/* eslint-disable import/first */

import React from "react";

import combineClasses from '../../combineClasses';
import { useTheme } from '../../muiTheming';

import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";

import ListItem from "@mui/material/ListItem";
import ListItemText from '@mui/material/ListItemText';

const ListItemLink = (props) => <ListItem button component="a" {...props} />;
import Flexbox from '../Flexbox';

const TopBarButton = ({label, ...props}) => {
    return (
        <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            {...props}
        >{label}</Button>
    );
};

const LinkButton = ({label, href, ...props}) => {
    return (
        <TopBarButton
            label={label}
            href={href}
            {...props}
        />
    );
};


const DropdownButton = ({label, path, menu, popoverClasses, anchorOrigin, transformOrigin, ...props}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const DropdownPopover = ({path, menu, ...props}) => (
        <>{
            Object.entries(menu).map( ([k,v]) => (
                <Flexbox item
                      className="HeaderMenuItem HeaderMenuLink HeaderMenuLevel1"
                      key={[...path,k].join("\t")}
                >
                    <ListItemLink href={v}>
                        <ListItemText primary={k} />
                    </ListItemLink>
                </Flexbox>
            ))
        }</>
    );

    return (
        <>
            <TopBarButton label={label} onClick={handleClick}{...props} />
            <Menu
                PopoverClasses={popoverClasses}
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <DropdownPopover path={path} menu={menu} />
            </Menu>
        </>
    );
};

export const HeaderMenuTopBarDropdowns = ({theme, themeClasses, className, menus, ...rest}) => {

    theme = useTheme();

    // anchorOrigin and transformOrigin handle where the dropdown menu
    // (the "transform") appears relative to the main button (the
    // "anchor").
    //
    // transformOrigin.horizontal=left,.vertical=top,
    // anchorOrigin.horizontal=left,.vertical=bottom will have the
    // popup menu open left aligned with the main button, with the
    // popup's top aligned with the main button's bottom.

    const anchorOrigin = {
        vertical: (
            rest.anchorOriginVertical ??
                theme.getProp('anchorOrigin.vertical') ??
                theme.getProp('anchorOriginVertical') ??
                'bottom'
        ),
        horizontal: (
            rest.anchorOriginHorizontal ??
                theme.getProp('anchorOrigin.horizontal') ??
                theme.getProp('anchorOriginHorizontal') ??
                'left'
        )
    };

    const transformOrigin = {
        vertical: (
            rest.transformOriginVertical ??
                theme.getProp('transformOrigin.vertical') ??
                theme.getProp('transformOriginVertical') ??
                'top'
        ),
        horizontal: (
            rest.transformOriginHorizontal ??
                theme.getProp('transformOrigin.horizontal') ??
                theme.getProp('transformOriginHorizontal') ??
                'left'
        )
    };

    const popoverClasses = themeClasses.popover ?? '';

    const menu = Object.entries(menus).map( ([k,v]) =>
        ('string' === typeof v) ? (
            <Flexbox item
                  className="HeaderMenuItem HeaderMenuLink HeaderMenuLevel0"
                  key={JSON.stringify([k,v])}
            >
                <LinkButton label={k} href={v} />
            </Flexbox>
        ) : (
            <Flexbox item
                  className="HeaderMenuItem HeaderMenuTree HeaderMenuLevel0"
                  key={JSON.stringify([k,v])}
            >
                <DropdownButton popoverClasses={{paper:popoverClasses}}
                                anchorOrigin={anchorOrigin}
                                transformOrigin={transformOrigin}
                                path={[k]}
                                label={k}
                                menu={v}
                />
            </Flexbox>
        )
    );

    return (
        <Flexbox item
              role="navigation"
              {...rest}
              className={combineClasses(className, 'HeaderMenuRoot')}
        >{
            menu
        }</Flexbox>
    );
};

export default HeaderMenuTopBarDropdowns;
