/* eslint-disable import/first, react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import Subtheme from './components/Subtheme';

export const withSubtheme = (Component, subtheme, options) => (props) => {
  const {
    applyStyling = true,
    applyProps = true,
    ...defaults
  } = options || {};

  const componentProps = useCallback(({ theme, className, ...props2 }) => (
    <Component {...props} {...props2} theme={theme} className={className} />
  ), [props]);

  return (
    <Subtheme subtheme={subtheme} defaults={defaults} applyStyling={applyStyling} applyProps={applyProps}>
      {componentProps}
    </Subtheme>
  );
};

export default withSubtheme;
