/* eslint-disable import/first */

import React from 'react';
import Typography from "@mui/material/Typography";

import { withTheme } from '../../muiTheming';

export const Text = withTheme( (props) => {

    let {
        theme,
        text, _text,
        variant, _variant,
        component, _component,
        children, ...rest
    } = props;

    // If no explicit text parameter, then use 'children' instead.
    text = text ?? children;

    // Define a lazy creator for interpolation context. Fun with functions!
    let ctx = () => (ctx = {...props, theme });

    // Interpolate dynamic props
    text      =      _text ? theme.interpolate(text, ctx)      : text;
    variant   =   _variant ? theme.interpolate(variant, ctx)   : variant;
    component = _component ? theme.interpolate(component, ctx) : component;

    return (
        <Typography variant={variant} component={component} {...rest}>
            {text}
        </Typography>
    );
});

export default Text;
