/* eslint-disable import/first */

import React from 'react';

import withSubtheme from '../../withSubtheme';

import PersonFlexbox from './PersonFlexbox';

export const Person = withSubtheme( ({theme, type, person, ...rest}) => {

    type = type ?? theme.getProp("type") ?? 'gridbox';

    switch (type) {
    case 'gridbox':
    default:
        return <PersonFlexbox
                   {...rest}
                   theme={theme}
                   person={person}
               />;
    }

}, 'person');


export default Person;
