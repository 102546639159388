import React from "react";

export const withGriditemProps = (WrappedComponent) => ({xs, ...rest}) => {

    switch (xs) {
    case 'true': case true: xs = true; break;
    case 'none': xs = undefined; break;
    case null: case undefined: xs = true; break;
    default:
        let _xs = parseInt(xs);
        xs = isNaN(_xs) ? xs : _xs;
    }

    return <WrappedComponent {...rest} xs={xs} />;
};

export default withGriditemProps;
