import React from 'react';

import refactorObject from './refactorObject';

export const propertyClean = (property, theme) => {

    // Convert attribute names to a single known schema
    property = refactorObject(property, {
        "bedroom": "bedrooms",
        "bathroom": "bathrooms",
        "ptype": "sector",
        "search_type": "sector",
        // Add more attribute name mappings here, as needed.
    });

    // Clean up values of search_type, sector, etc.:
    switch (property.sector) {
    case 'Sales':
    case 'S':
    case 'for-sale':
    case 'for sale':
        property.sector = 'for sale';
        break;

    case 'Lettings':
    case 'L':
    case 'to-let':
    case 'to let':
        property.sector = 'to let';
        break;

    default:
        console.warn(`Unknown value for property sector: ${property.sector}`);
        delete(property.sector);
    }

    const replaceSpecialChar = (str) => {
        if (typeof str === 'array' || typeof str === 'object')
            return str.join(', ').replace(/[^\w\s]/gi, ' ');
        return str.replace(/[^\w\s]/gi, ' ');
    } 

    // Generate a summary sentence
    // @todo this is probably inadequate; projects will want their own
    // sentences. If so, set summaryFn in the passed theme
    if (undefined === property.summary) {

        let summaryFn = theme?.getProp('summaryFn');
        if (undefined === summaryFn) {
            summaryFn = (property) => {
                const bedrooms = property.bedrooms;
                return [
                    (0===bedrooms ? 'Studio ' : 0>bedrooms ? bedrooms+' bedroom ' : ''),
                    (property.building ? ((replaceSpecialChar(property.building))) : ''),,
                    (property.sector==='for sale' ? 'for sale' : property.sector==='Lettings' ? 'to let' : '')
                ].join(' ');
            }
        }

        property.summary = summaryFn(property);
    }
    
    return property;
};

export default propertyClean;

