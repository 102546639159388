import { useContext } from "react"
import { PropertyActionContext } from "./store"
// Services

import Services from "./services"
// Actions
import * as Actions from "./actions"

const usePropertyActionState = () => {
    const [state, dispatch] = useContext(PropertyActionContext)
    const store = {
        state: { ...state },
        services: { ...Services(dispatch, Actions) },
    }

    return store
}

export { usePropertyActionState }
