/* eslint-disable import/first */

import React from 'react';

import { createTheme } from '@mui/material/styles';

import withStarberryFunctions from './withStarberryFunctions';

export const createStarberryTheme = (obj, ...rest) => {

    let src;
    let theme;

//    if (undefined === obj) {
//        console.error("No theme data passed to createStarberryTheme");
//        throw new Error("No data passed to createStarberryTheme");
//    }

    if (undefined === obj?._src) {

        if (undefined !== obj.breakpoints?.between /*pretty obscure, right?*/) {
            console.log(obj);
            console.error("Theme data passed to createStarberryTheme is already a MUI theme");
            return obj;
        }

        // Store the object data for storage (used by subtheming)
        src = Object.assign({}, obj);
    }
    else {
        // Preserve the source
        src = obj._src;
    }

    // Create the new theme
    theme = createTheme(obj, ...rest);

    // Store the object data for storage (used by subtheming)
    theme._src = src;

    // Add functions
    if (undefined === theme?.getProp)
        theme = withStarberryFunctions(theme);

    return theme;
};

export default createStarberryTheme;
