export default {
    "container": {
        "direction": "column",
        "alignItems": "center",
        "alignContent": "center",
        "order": ['header', 'media', 'article', 'aside'],
        "wrap": "wrap",
        "container": true, // ie. <Grid container>
        "sx": {
            "display": "flex",
        },
    },
    "header": {
    },
    "article": {
        "direction": "column",
        "alignItems": "center",
        "alignContent": "center",
    },
    "media": {
        "group": "propertyresult" // Image group to use
    },
    "aside": {
    },

    "address": {
        "in": "header",
        "variant": "subtitle1",
        "component": "header",
    },

    "summary": {
        "in": "article",
        "variant": "subtitle2",
        "component": "p",
    },

    "description": {
        "in": "article",
        "variant": "subtitle1",
        "component": "p",
    },

    "price": {
        "in": "aside",
        "variant": "subtitle1",
        "component": "div",
    },
};
