// FooterWithNavBoxes is intended to be one of those big footers with a
// grid of navigation in it; eg. like on our `carolineolds` or
// `starberrytv` sites.
//
// If you need a footer that's different in structure / philosophy,
// consider writing a different component within `Footer/`,
// eg. `Footer/FooterWithDropdowns` or similar.

import React from "react";


import Grid from "@mui/material/Grid";

import { withTheme } from '../../muiTheming';

import SiteByStarberry from '../SiteByStarberry';
import Breadcrumbs from '../Breadcrumbs';

import FooterImprint from '../FooterImprint';
import FooterMenus from '../FooterMenus';
import FooterSocial from '../FooterSocial';


export const FooterWithNavBoxes = withTheme( ({theme, children, ...props}) => {

    const sep =
          props.breadcrumbseparator ??
          theme.getProp('breadcrumbSeparator') ??
          ' / ';

    const breadcrumbs =
          props.breadcrumbs;

    const menus =
          theme.getProp('menu.menus') ??
          theme.getProp('menus') ??
          [];

    const copyright =
          theme.getProp('copyright');

    return (
        <Grid container {...props}>
            {
                breadcrumbs ? (
                    <Grid item className="breadcrumbs" role="navigation">
                        <Breadcrumbs separator={sep}>{breadcrumbs}</Breadcrumbs>
                    </Grid>
                ) : (
                    <Grid item className="noBreadcrumbs">
                    </Grid>
                )
            }

            <Grid item container
                  justifyContent="space-between"
                  direction="row"
                  role="navigation"
                  xs={12}
            >
                <FooterMenus menus={menus} />
            </Grid>

            <Grid item container
                  role="contentinfo"
                  justifyContent="space-between"
                  direction="row"
                  xs
            >
                <FooterImprint copyright={copyright} xs={6} />
                <SiteByStarberry xs={3} />
                <FooterSocial xs={6} />
            </Grid>
        </Grid>
    );
});


export default FooterWithNavBoxes;
