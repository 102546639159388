/* eslint-disable import/first */

import React from 'react';

import clsx from 'clsx';
import { deepmerge } from '../../deepmerge';

import Flexbox from '../Flexbox';
import CurrencyFormat from '../CurrencyFormat';
import AddressFormat from '../AddressFormat';
import Image from '../Image';

import Typography from "@mui/material/Typography";

/*
const useStyles = makeStyles((theme) => ({
    container: (props) => ({
        display: "flex",
        flexWrap: "nowrap"
    }),
    media: (props) => ({
        width: props.imageWidth,
        height: props.imageHeight,
        display: 'block',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        "& *": {
            objectFit: "cover"
        }
    }),
    body: (props) => ({
        display: "flex",
    })
}));
*/

import defaults from './defaults';

export const PropertyResultFlexbox = (props) => {

    let {
        theme,
        property, _property,
        className,
        ...rest
    } = props;

    // Get props for the individual parts
    let _props = deepmerge(
        defaults,
        { "container": theme.getProp('container') ?? {},
          "header": theme.getProp('header') ?? {},
          "article": theme.getProp('article') ?? {},
          "aside": theme.getProp('aside') ?? {},
          "media": theme.getProp('media') ?? {} },

        theme.getProp('props') ?? {},
        rest,
    );

    property = _property ?
        theme.interpolate(property, {..._props, theme}) :
        property ??
        {};

    //const classes = useStyles(_props);
    // const jss = Object.entries(_props).reduce( (a,[zone,zoneProps]) => {
    //     if (zoneProps?.sx) a[zone] = zoneProps.sx;
    //     return a;
    // }, {});

    //const classes = makeStyles(jss, {name:"PropertyResult"})();
    const classes = [];

    // @todo These should be moved to sub-components, to allow a more
    // modular building of the card, but that's a bigger task.
    //
    // Prepare all components
    const typoFn = (item, fn) => (itemProps) => {
        const { variant, component, color, include, ...rest } = itemProps;
        return (
            <Typography variant={variant ?? _props[`${item}Variant`]}
                        component={component ?? _props[`${item}Component`]}
                        color={color ?? _props[`${item}Color`]}
                        className={`propertyresult-${item}`}
                        {...rest}
            >{
                fn()
            }</Typography>
        );
    };

    // Prepare content
    const entries = {
        "address": typoFn("address", () => <AddressFormat property={property} />),
        "summary": typoFn("summary", () => property.summary),
        "description": typoFn("description", () => property.description),
        "price": typoFn("price", () => <CurrencyFormat value={property.price} />),
    };

    // Construct content zones: header, article, etc.
    const components = {};
    Object.entries(_props).forEach( ([entry, _entryProps]) => {

        // Only process things that look like zones in propertyresult.props
        if ('object' !== typeof _entryProps) return;

        let {in:entryIn, ...entryProps} = _entryProps;

        // If 'include' is false, skip it
        if (undefined !== entryProps.include && ! entryProps.include) return;

        // Need content to render
        if (undefined === entries[entry]) return;
        let fn = entries[entry];

        // If the content is set as in another zone, move it
        let zone = entryIn ?? entry;

        // Decide role
        let role;
        if (entryProps.role)
            role = entryProps.role;
        else if (-1 !== ['header', 'media', 'article', 'aside'].indexOf(zone))
            role = zone;
        else
            role = undefined;

        // Clear component list for the zone we're adding to
        if (undefined === components[zone])
            components[zone] = [];

        // Render the component
        components[zone].push(fn(entryProps));
    });

    // Prepare image/media component
    let {mediaClassName, imageWidth, imageHeight} = _props.media ?? {};
    components.media = components.media ?? [];
    components.media.push(
        <Image src={property.image_url ?? property.src}
               className={clsx(mediaClassName, classes.media, "propertyresult-image")}
               width={imageWidth}
               height={imageHeight}
               group={_props.media.group}
        />
    );

    // const getOption = (x) => _props[x];

    const Zone = ({zone, children, ...props}) => {
        let zoneProps = deepmerge(
            props,
            _props[zone],
            props
        );

        return (
            <Flexbox {...zoneProps} className={clsx(classes[zone], `propertyresult-${zone}`)}>
                {children}
            </Flexbox>
        );
    };

    return (
        <Zone item container
              zone="container"
              className={clsx(classes.root,"propertyresult")}
        >
            {
                _props.container.order.map( z => (
                    <Zone item zone={z} key={z} children={components[z]} />
                ))
            }
        </Zone>
    );
};

export default PropertyResultFlexbox;
