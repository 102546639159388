import { has, set, get, isArray } from 'lodash';

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SERVICE_ERROR':
          return {
            ...state,
            loading: false,
            error: true,
            message: action.payload.message,
            status: action.payload.status
          };

        case 'API_ERROR':
            return {
              ...state,
              loading: false,
              error: true,
              message: action.payload.message,
              status: action.payload.status
            };

        case "DATA_LOADING":
            let params = {
                loading: true,
                error: false,
                success: false,
                message: "",
            }
            if (action?.payload) params = { ...params, ...action.payload }
            return {
                ...state,
                ...params,
            }
        case "GET_APPOINTMENTS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                propertyAppointments: action.payload,
            }
        case "GET_FEEDBACKS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                propertyFeedbacks: action.payload,
            }

        case "GET_OFFERS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                propertyOffers: action.payload,
            }

        case "GET_TENANCIES":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                propertyTenances: action.payload,
            }
        case "GET_TRANSACTIONS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                propertyTransactions: action.payload,
            }
        case "GET_TASKS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                propertyTasks: action.payload,
            }

        case "GET_WORKS_ORDERS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                worksOrders: action.payload,
            }
        case "GET_DOCUMENTS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                documents: [
                    ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    // ...action.payload,
                    
                ],
            }
        case "GET_PROPERTY_PENDING_ACTIONS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                pendingActions: action.payload,
            }
        case "GET_PROPERTY_RECENT_ACTIVITIES":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                recentActivities: action.payload,
            }
        case "GET_PROPERTY_STATS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                propertyStats: action.payload,
            }
        case "GET_HAS_APPOINTMENTS":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                hasAppointments: action.payload,
            }
        case "GET_CALENDAR_LIST":
            return {
                ...state,
                loading: false,
                loading_actions: false,
                calendarLists: action.payload,
            }
        case "HIDE_MY_PROPERTY":
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
            }
        case "UN_HIDE_MY_PROPERTY":
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
            }
        case "GET_ARCHIVED_PROPERTIES":
            return {
                ...state,
                loading: false,
                archivedProperties: action.payload,
            }
        case "UPDATE_STATE":
            return {
                ...state,
                ...action.payload
            }
        case "CHANGE_STATE":
            if (has(state, action.payload.key)) {
                if (action.payload.postAction === 'remove') {
                    let targetItem = get(state, action.payload.key, false);
                    if (targetItem && isArray(targetItem)) {
                        let newItem = targetItem.filter(x => x.crm_id !== action.payload.crm_id);
                        let newState = {...state};
                        set(newState, action.payload.key, newItem);
                        return newState;
                    }
                } else if (action.payload.postAction === 'add') {
                    let targetItem = get(state, action.payload.key);
                    targetItem.unshift(action.payload)
                    let newState = {...state};
                    set(newState, action.payload.key, targetItem);
                    return newState;
                }
            }
            return state;

        default:
            return state
    }
}

export default Reducer
