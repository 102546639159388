import React, { useMemo, useCallback } from 'react';

import createStarberryTheme from '../../createStarberryTheme';
import { ThemeProvider, useTheme, withTheme } from '../../muiTheming';

import withThemeStyling from '../../withThemeStyling';
import withThemeProps from '../../withThemeProps';

import { deepmerge_selective } from '../../deepmerge';
import { useContext } from 'react';
import { useRef } from 'react';


//////////////////////////////////////////////////////////////////////////
// TEMPORARY CACHING HACK


// Really stupid, trivial, deterministic version of useMemo so we can
// verify what's actually going on.
//
// I'm guessing this should really operate "properly" like useMemoOne(),
// but I don't want to worry about useEffect, etc right now, while
// we're trying to figure out what's going on.
const cache = {};
const stupidUseMemo = (fn, name) => {
    if (undefined === cache[name])
        cache[name] = fn();
    return cache[name];
};

// END OF TEMPORARY CACHING HACK
//////////////////////////////////////////////////////////////////////////

/**
 * Merge `theme.subtheme[subtheme]` over the top of current `theme`, and
 * compile it as a MUI / Starberry theme.
 *
 * `subtheme` can be slash-separated, as subthemes can themselves contain subthemes.
 *
 * `defaults` can be an additional subtheme template to layer the new
 * subtheme on top of.
 *
 * If `applyStyling` is set, also style the contents using the new `sx`
 * data in the subtheme.  If `applyStyling` is a string or React component,
 * a wrapper is created for the styling.
 *
 * If `applyProps` is set, it'll load the "props" from the theme and apply
 * them too.
 *
 * For children, it's good to pass a function that takes props className
 * and theme so it can be rendered with that new data.
 */

export const SubthemeContext = React.createContext({
    getSubtheme() {},
});

export const SubthemeProvider = ({ children }) => {
    const subthemeDefs = useRef({})

    const getSubtheme = useCallback((name, theme, subthemeSrc, defaults) => {
        if (subthemeDefs.current[name]) {
            return subthemeDefs.current[name];
        }

        // Merge the clean theme source with the subtheme
        const themeSrc = deepmerge_selective(
            ['sx'],
            theme._src ?? theme,                     // Start with the clean pre-createMuiTheme data
            defaults,
            {name, subthemes: theme._src.subthemes}, // Merge in the composed name and the current theme's subthemes
            subthemeSrc,                             // Overlay the subtheme itself
            {_parent: theme._src}                    // and include the parent, for "../"
        );

        // And compile the theme for MUI
        const subtheme = createStarberryTheme(themeSrc);

        subthemeDefs.current[name] = subtheme

        return subtheme
    }, [])

    return (
        <SubthemeContext.Provider value={{
            getSubtheme,
        }}>
            {children}
        </SubthemeContext.Provider>
    )
}

export const useSubtheme = (subtheme, defaults = {}) => {
    const theme = useTheme();
    const { getSubtheme } = useContext(SubthemeContext)

    if (undefined === theme?.getProp) {
        throw new Error("Theme passed to <Subtheme> is not initialised properly:", theme);
    }

    const subthemePath = useMemo(() => {
        if (!subtheme) {
            throw new Error("No subtheme passed to <Subtheme>");
        }

        // Resolve sub-subthemes.  If it's just a simple subtheme name,
        // this'll just give us an array with a single value, ie. same as
        // "subthemePath = [subtheme]"
        let subthemePath = subtheme;
        if ('string' === typeof subthemePath)
            subthemePath = subthemePath.split('/');

        return subthemePath
    }, [subtheme])

    const [name, subthemeSrc] = useMemo(() => {

        // Get the subtheme or sub-subtheme data until we run out.
        let subthemeSrc = theme;
        let name = theme.getProp('name');
        while (subthemeSrc && subthemePath && subthemePath.length > 0) {
            let key = subthemePath?.shift();
            if ('..' === key) {
                subthemeSrc = theme._parent;
                name = name.replace(/_[^_]+$/, '');
            }
            else {
                subthemeSrc = subthemeSrc.subthemes?.[key];
                name = `${name}_${key}`;
            }
        }

        return [name, subthemeSrc]
    }, [subthemePath, theme])

    // Create theme alteration (replacement) function for MuiThemeProvider
    const mergedTheme = useMemo(() => {
        // If the subtheme is not there (perhaps it's already been applied?) then skip this.
        if (undefined === subthemeSrc) {
            console.warn(`Failed to get subtheme ${subtheme} from current theme ${theme.getProp('name')}`);
            return;
        }

        return getSubtheme(name, theme, subthemeSrc, defaults)
    }, [name, getSubtheme, defaults, subtheme, subthemeSrc, theme]);

    return mergedTheme
}

export const Subtheme = ({ children, subtheme, ...props }) => {
    const { defaults = {}, applyStyling = true, applyProps = true, ...restProps } = props;

    const _subtheme = useSubtheme(subtheme, defaults)

    // Add children executor wrapper
    const Children = useMemo(() => {
        let Children = (children instanceof Function) ? children : (props) => children;

        if (applyStyling)
            Children = withThemeStyling(Children, 'boolean'=== typeof applyStyling ? undefined : applyStyling);

        if (applyProps)
            Children = withThemeProps(Children);

        // Prepare for the newly-altered theme to be passed to the Children on
        // rendering.
        return withTheme(Children);
    }, [children, applyProps, applyStyling])

    // Wrap the children in a theme change
    return (
        <ThemeProvider theme={_subtheme}>
            <Children {...restProps} />
        </ThemeProvider>
    );
};

export const _Subtheme = ({ children, subtheme, defaults = {}, ...restProps }) => {
    // restProps currently is always undefined

    const augmentedTheme = useSubtheme(subtheme, defaults)

    // Add children executor wrapper
    const Children = children;

    // Wrap the children in a theme change
    return (
        <ThemeProvider theme={augmentedTheme}>
            <Children {...restProps} />
        </ThemeProvider>
    );
};


export default React.memo(Subtheme);

