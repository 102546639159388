/* eslint-disable import/first */

import React from "react";

import { useTheme } from '../../muiTheming';

import Grid from "@mui/material/Grid";

export const FooterSocial = ({theme, ...rest}) => {
    const _theme = useTheme();
    theme = theme ?? _theme;

    const social = theme.getProp('social', []);

    return (
        <Grid item component="div" {...rest}>{social ? (<ul>{
            Object.entries(social).map(([k,v]) => (
                <li key={k}>
                    <a href={v} target="_blank">
                        <i className={`icon social-icons icon-${k.toLowerCase()}`}>{k}</i>
                    </a>
                </li>
            ))
        }</ul>) : undefined}</Grid>
    );
};

export default FooterSocial;
