export const combineClasses = (...as) => (
    [...new Set(as.reduce((a, c) => {
        const t = typeof c;

        if (t === 'string') return [...a, ...(c.split(' '))];

        if (c instanceof Array) return [...a, c];

        if (t === 'object') {
            if (c.root) {
                return [...a, c.root];
            }
            return [...a, Object.values(c).shift()];
        }

        return a;
    }, []))].filter((x) => x).join(' ')
);

export default combineClasses;
