import {
  getPendingActionData,
} from '../api';
import {
  doLogout,
} from '../utils';
import { API_ERROR_MESSAGE } from "../constants";


const services = (dispatch, actions) => {

    const handleError = (error) => {
        if (error.response) {
          if (error.response?.data?.statusCode === 401) doLogout();
          dispatch(
            actions.myaccountActions(
              'API_ERROR',
              {
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          dispatch(
            actions.myaccountActions(
               'SERVICE_ERROR',
              {
              message: API_ERROR_MESSAGE,
              status: 500
            })
          );
        }
    }

    return ({
      getPendingActions: (payload) => {
        dispatch(actions.myaccountActions('DATA_LOADING', {loading_actions: true}))
        getPendingActionData(payload).then((res) => {
          dispatch(actions.myaccountActions('GET_PENDING_ACTIONS', res.data));
        })
        .catch((error) => {
          handleError(error);
        });
      }
    })
}

export default services;
