/* eslint-disable import/first */

import React from 'react';


import {
    // withStyles,
    // makeStyles,
//    createMuiTheme,
//    useTheme,
//    MuiThemeProvider,
} from './muiTheming';

export const withStarberryFunctions = (theme) => {

    // String interpolation
    // Recommended:
    /*
    // Define a lazy creator for interpolation context. Fun with functions!
    let ctx = () => (ctx = () => ({...props, theme }));

    foo = _foo ? theme.interpolate(foo, ctx) : foo;
    bar = _bar ? theme.interpolate(bar, ctx) : bar;
    */


    // Parse an incoming string for the limited object paths that we can easily
    // pull out from a context.  So, for example, "{theme.sampleData.0.name}" is
    // kinda equivalent to `${theme.sampleData[0].name}`
    //
    // Typically, _context will contain theme, and whatever might
    // be useful.  It can also be a function to allow for lazy evaluation.
    //
    // It can do defaults too, so {theme.foo|bar} will return 'bar' if
    // theme.foo is not set.
    //
    // In an ideal world, we'd really just pass a `` string, but those are
    // really compile-time only, so we'd need a full ES6 interpreter to
    // deal with it.
    theme.interpolatePlaceholders = function (text, _context) {

        let recurse;
        recurse = (text, level=0) => {

            // Just in case...
            if (level > 10) return text;

            // We'll prepare context only if we need it.
            let context = undefined;

            // Define a recursive local function to fill a path placeholder
            let resolvePlaceholders;
            resolvePlaceholders = (path, context) => {
                if ('' === path)
                    return context;

                if ('string' === typeof path)
                    path = path.split('.');

                if (!path || !path.length)
                    return context;

                if (!context)
                    return context;

                let [head, ...tail] = path;
                return resolvePlaceholders(tail, context[head]);
            };

            switch (typeof text) {
            case 'string': break;
            case 'function': text = text(theme); break;
            default: return `${text}`;
            }

            // Replace placeholders of the form {var} and {var|default}
            // where 'var' is evaluated as a path within context, eg. `theme.copyright`
            let value = text.replace(/(?!\\)\{([^}]+?)(?:\|([^}]*))?}/g, (str, p1, p2) => {

                // Prepare data block to pass for dynamic text resolution
                if (undefined === context) {
                    if ('function' === typeof _context) {
                        // Evaluate incoming function
                        context = _context();
                        context = {theme, ...context};
                    }
                    else if ('object' === typeof _context) {
                        context = {theme, ..._context};
                    }
                    else {
                        context = {theme};
                    }
                }

                try       {
                    let x = resolvePlaceholders(p1, context) ?? p2 ?? str;

                    if ('function' === typeof x)
                        x = x(theme);

                    return x;
                }
                catch (e) { return str; }
            });

            if (text === value)
                return value;
            else
                return recurse(value, level+1);
        };

        return recurse(text, 0);
    };

    // Helper, effectively nullish coalescing (`??`) but with '' too.
    const blank_undefined = (x) => ( ''===x ? undefined : x );

    // Shortcut for theme.interpolatePlaceholders() to make code cleaner
    // within components.
    theme.interpolate = (x, context) => blank_undefined(
        undefined!==context ? theme.interpolatePlaceholders(x, context) : x
    );

    function _getPropRecurse (path, obj) {
        if (path) {
            let [key, ...rest] = path;
            let val = obj[key];

            if (undefined === val)
                return undefined;

            if (rest.length)
                return _getPropRecurse(rest, val);
            else
                return val;
        }

        return undefined;
    };

    function _getProp (keyPath, ...objs) {

        let val;
        let segments = keyPath.split('.');

        if (!objs)
            return undefined;

        for (let obj of objs) {
            if (undefined !== obj) {
                val = _getPropRecurse(segments, obj);
                if (undefined !== val)
                    return val;
            }
        }

        return undefined;
    }

    theme.getProp = function (k) {
        return _getProp(k, this);
    };


    function _getThemeColorRecurse (segments, obj) {
        let val;

        val = _getPropRecurse(['colors',...segments], obj);
        if (undefined !== val)
            return val;

        val = _getPropRecurse(['palette',...segments], obj);

        return val;
    };

    function _getColor (keyPath, ...objs) {

        let segments = keyPath.split('.');

        let val = (() => {
            if (!objs)
                return undefined;

            for (let obj of objs) {
                if (undefined !== obj) {
                    val = _getThemeColorRecurse(segments, obj);
                    if (undefined !== val)
                        return val;
                }
            }
        })();

        val = ((val) => {
            let c = 0;
            while ( (++c<10) && 'function' === typeof val)
                val = val(this);

            if ('string' === typeof val) {
                let nval = _getColor(val, ...objs);
                if (undefined !== nval) {
                    return nval;
                }
            }

            return val;
        })(val);


        if (val instanceof Object) {
            if (val.main)
                return val.main;
            else if (val.primary)
                return val.primary;
            else if (val.default)
                return val.default;
            else
                return Object.entries(val).shift()[1];
        }

        return val;
    };

    theme.getColor = function (k) {
        return _getColor(k, this);
    };

    return theme;
};

export default withStarberryFunctions;
