import { useContext } from 'react'
import { FormContext } from './store'
// Services

import Services from './services'
// Actions
import * as Actions from './actions'

const useFormState = () => {
  const [state, dispatch] = useContext(FormContext)
  const store = {
    state: { ...state },
    services:{ ...Services(dispatch, Actions) },
  }

  return store
}

export { useFormState }
