import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"

const initialState = {
    propertyAppointments: [],
    propertyFeedbacks: [],
    propertyOffers: [],
    propertyTenances: [],
    propertyTransactions: [],
    propertyTasks: [],
    worksOrders: [],
    documents: []
}

const PropertyActionStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)
    return (
        <PropertyActionContext.Provider value={[state, dispatch]}>
            {children}
        </PropertyActionContext.Provider>
    )
}

export const PropertyActionContext = createContext(initialState)
export default PropertyActionStoreProvider
