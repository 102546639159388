/* eslint-disable import/first */
import React from 'react';

import { useTheme } from './muiTheming';

export const withThemeProps = (Component) => (props) => {

    const theme = useTheme();

    const theme_props = theme.getProp('props') ?? {};

    return (
        <Component {...props} {...theme_props} />
    );
};

export default withThemeProps;
