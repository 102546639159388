import algoliasearch from "algoliasearch/lite"

export const searchClient = algoliasearch(
    `${process.env.GATSBY_ALGOLIA_APPLICATION_ID}`,
    `${process.env.GATSBY_ALGOLIA_SEARCH_API_KEY}`
)
export const DEBOUNCE_TIME = 500
export const INDEX_NAME = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`

export const SEARCH_BASE_URL = process.env.GATSBY_SEARCH_BASE_URL || '/properties'

export const DEFAULT_LOCATION = 'London'
export const DEFAULT_REFINEMENT = ["sales", "lettings"];
export const FIELD_TYPE_MAP = {
    'building': 'refinementList[building]'
}

export const MAP_KEY = process.env.GATSBY_MAP_KEY || "";
