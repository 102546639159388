import { get } from 'lodash';
import { getUserPicture } from '../utils';

const Reducer = (state, action) => {
  let data;
  switch (action.type) {

    case 'GET_USER_DATA':
      data = action.payload.data;
      return {
        ...state,
        profile: data,
        user: {
          name: data.forename || data.username || data.email,
          job_title: data?.job_title,
          uid: data.id,
          contact_id: get(data, 'contact_id[0]', false),
          picture: getUserPicture(data)
        },
        success: true,
        loading: false,
        preference_success: false,
        error: false,
        message: '',
        status: action.payload.status
      };

    case 'RESET_MESSAGE':
      return {
        ...state,
        success: false,
        loading: false,
        preference_success: false,
        error: false,
        message: false
      };

    case 'PROFILE_LOADING':
      return {
        ...state,
        loading: true,
    };
    case 'COMPLETE_PROFILE_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'COMPLETE_PROFILE_SUCCESS':
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        complete_profile_success: true,
      };
    case 'UPDATE_MESSAGE':
      return {
        ...state,
        success: false,
        loading: false,
        preference_success: false,
        error: false,
        message:false,
        ...action.payload
      };

    case 'PROFILE_UPDATE':
      data = action.payload.data;
      return {
        ...state,
        success: true,
        loading: action?.payload?.loading || false,
        preference_success: false,
        complete_profile_success: false,
        error: false,
        profile: data,
        user: {
          ...data.user,
          name: data.forename || data.username || data.email,
          uid: data.id,
          contact_id: get(data, 'contact_id[0]', false),
          picture: getUserPicture(data.user)
        },
        message: (action?.payload?.message || "Profile Updated"),
        status: action.payload.status
      };

    case 'PREFERENCE_UPDATE':
        data = action.payload.data;
        return {
          ...state,
          preference_success: true,
          loading: action?.payload?.loading || false,
          error: false,
          profile: data,
          user: {
            ...data.user,
            name: data.forename || data.username || data.email,
            uid: data.id,
            contact_id: get(data, 'contact_id[0]', false),
            picture: getUserPicture(data.user)
          },
          message: (action?.payload?.message || "Profile Updated"),
          status: action.payload.status
        };

    case 'GET_IDENTITY_DOCUMENTS':
        data = action.payload.data;
        return {
          ...state,
          loading: false,
          identityDocuments: action.payload?.data || [],
        };

    case 'PASSWORD_UPDATE':
      return {
        ...state,
        success: true,
        loading: false,
        preference_success: false,
        error: false,
        fieldValues: false,
        profile: action.payload.data,
        message: action.payload.data ? 'Password Updated' : action.payload.data,
        status: action.payload.status
      };

  case 'UPDATE_STATE':
    return {
      ...state,
      ...action.payload,
    }

    case 'SERVICE_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload.message,
        status: action.payload.status
      };

    case 'API_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload.message,
        status: action.payload.status
      };

    default:
      return state;
  }
};

export default Reducer;
