// @todo this need to be changed with a generic one, not gatsby specific
import { navigate } from "gatsby"

const doRedirect = url => {
    if (typeof window !== `undefined`) {
        // looking for external link
        if (url.match(/https|http/))
            window.location.href = url
        else
            navigate(url);
    }
}

// reading params from location search
const useQuery = ( location, key ) => {
    const query = new URLSearchParams( location.search);
    return query.get(key);
}

// reading params from url
const goBack = () => {
    navigate(-1);
}

export { doRedirect, useQuery, goBack }
