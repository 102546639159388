export const authProcess = (payload) => {
  return {
    type: 'LOGIN_PROCESS',
    payload
  }
}

export const verifyProcess = (payload) => {
  return {
    type: 'VERIFY_EMAIL',
    payload
  }
}

export const updatePersona = (payload) => {
  return {
    type: 'UPDATE_PERSONA',
    payload
  }
}

export const authLoading = (payload) => {
  return {
    type: 'LOADING',
    payload
  }
}

export const authError = (payload) => {
  return {
    type: 'LOGIN_ERROR',
    payload
  }
}

export const tokenError = (payload) => {
  return {
    type: 'TOKEN_ERROR',
    payload
  }
}

export const providerLoginError = (payload) => {
  return {
    type: 'PROVIDER_LOGIN_ERROR',
    payload
  }
}

export const authLogout = (payload) => {
  return {
    type: 'USER_LOGOUT',
    payload
  }
}

export const authlogin = (payload) => {
  return {
    type: 'USER_LOGIN',
    payload
  }
}

export const forgotPassword = (payload) => {
  return {
    type: 'FORGOT_PASSWORD',
    payload
  }
}

export const resetPassword = (payload) => {
  return {
    type: 'RESET_PASSWORD',
    payload
  }
}

export const profileUpdate = (payload) => {
  return {
    type: 'PROFILE_UPDATE',
    payload
  }
}

export const getUserData = (payload) => {
  return {
    type: 'GET_USER_DATA',
    payload
  }
}

export const resetMessage = (payload) => {
  return {
    type: 'RESET_MESSAGE',
    payload
  }
}

export const updateMessage = (payload) => {
  return {
    type: 'UPDATE_MESSAGE',
    payload
  }
}

export const updateState = (payload) => {
  return {
    type: 'UPDATE_STATE',
    payload
  }
}

export const getPendingActionCount = (payload) => {
  return {
    type: 'GET_PENDING_ACTIONS_COUNT',
    payload
  }
}

// export const providerLogin = (payload) => {
//   return {
//     type: 'PROVIDER_LOGIN',
//     payload
//   }
// }

export const serviceError = (payload) => {
  return {
    type: 'SERVICE_ERROR',
    payload
  }
}

export const apiError = (payload) => {
  return {
    type: 'API_ERROR',
    payload
  }
}
