/* eslint-disable import/first */

import React from "react";

import withSubtheme from '../../withSubtheme';

import PageBlock from '../PageBlock';


export const PageFooter = withSubtheme( props => {

    let {theme, className, contained, children, ...rest} = props;

    contained = contained ?? theme?.getProp('contained');

    const jss = {
        // Some typical resets
        "& a": {
            "color": "currentColor"
        },
        "& ul": {
            "margin": 0,
            "padding": 0,
            "list-style": "none",
            "display": "block"
        }
    };

    // <Grid> + styling has trouble with component="…":
    // https://stackoverflow.com/questions/64651768/mixing-style-functions-with-material-ui-grid-and-component
    // so omit `component="footer"`

    return (
        <PageBlock theme={theme}
                   jss={jss}
                   role="navigation"
                   className={className}
                   contained={contained}
                   {...rest}
        >
            {children}
        </PageBlock>
    );
}, 'footer');

export default PageFooter;
