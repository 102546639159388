/* eslint-disable import/first */

/**
 * @copyright © 2020, Starberry Ltd
 */

import React from 'react';
import PropTypes from 'prop-types';

import { LogoOrLogotype } from '../Logo';

export const Logotype = (props) => (
    LogoOrLogotype({type1: 'logotype', type2: 'logo', ...props})
);

Logotype.propTypes = {
//    wrapper: PropTypes.bool
};

Logotype.defaultProps = {
//    wrapper: true
};

export default Logotype;
