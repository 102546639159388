/* eslint-disable import/first */

import React from 'react';

import clsx from 'clsx';

import { deepmerge } from '../../deepmerge';
import { makeStyles } from '../../muiTheming';

import Avatar from '../Avatar';
import Flexbox from '../Flexbox';

import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
    container: props => ({
        display: "flex",
        flexWrap: "nowrap"
    }),
    text: props => ({
        display: "flex",
//        "& > *": { display: "flex" }
    }),
    avatar: props => ({})
}), {name: 'Person'});



export const PersonFlexbox = (props) => {

    let {
        theme,
        person, _person,
        src, _src,

        textProps,
        containerProps,
        avatarProps,

        line1, _line1,
        line2, _line2,
        line3, _line3,
        className,
        ...rest
    } = props;

    // Get props for the individual parts
    let _props = deepmerge(
        {
            "container": {
                "direction": "column",
                "alignItems": "center",
                "alignContent": "center",
                "wrap": "nowrap"
            },
            "text": {
                "direction": "row",
                "alignItems": "center",
                "alignContent": "center"
            },
            "avatar": {
                "variant": "square"
            }
        },

        { "container": containerProps ?? {},
          "text": textProps ?? {},
          "avatar": avatarProps ?? {} },

        { "container": theme.getProp('container') ?? {},
          "text": theme.getProp('text') ?? {},
          "avatar": theme.getProp('avatar') ?? {} },

        theme.getProp('props') ?? {},
        props,
    );

    // Build the data
    person = _person ?
        theme.interpolate(person, {..._props, theme}) :
        person ??
        undefined;

    // Define a lazy creator for interpolation context
    let ctx = () => (ctx = {..._props, theme, person });

    // Interpolate dynamic props
    line1 = _line1 ? theme.interpolate(line1, ctx) : line1 ?? person.name;
    line2 = _line2 ? theme.interpolate(line2, ctx) : line2 ?? person.title ?? person.handle;
    line3 = _line3 ? theme.interpolate(line3, ctx) : line3;

    src = _src ? theme.interpolate(src, ctx) : src ?? person.src;

    const classes = useStyles();


    // Function to extract (get and remove) a prop from the element's
    // props, or failing that, getting it from the theme.
    const extractOption = (k) => {
        if (undefined !== rest[k]) {
            const ret = rest[k];
            delete rest[k];
            return ret;
        }
        return theme.getProp(k);
    };

    const width =
          props.width ??
          theme.getProp('boxWidth') ??
          '';

    // Prop overrides, or Theme layout's avatar props
    const avatarLayoutStyling =
          props.avatar ??
          theme.getProp('avatar');

    // Prop overrides, or Theme's global avatar props
    const avatarGlobalStyling =
          props.avatar ??
          theme.getProp('avatar');

    const size =
          props.size ??
          avatarLayoutStyling?.size ??
          theme.getProp('size') ??
          avatarGlobalStyling?.size ??
          5;

    const variant =
          props.variant ??
          avatarLayoutStyling?.variant ??
          theme.getProp('avatarVariant') ??
          avatarGlobalStyling?.variant ??
          "square";

    const rounding = 'rounded'===variant ? (
        props.avatarBorderRadius ??
            avatarLayoutStyling?.borderRadius ??
            theme.getProp('avatarBorderRadius') ??
            avatarGlobalStyling?.borderRadius
    ) : undefined;


    const line1Code = (_props.line1Include ?? _props.text?.line1Include ?? true) ? (
        <Typography variant={_props.line1Variant ?? _props.text?.line1Variant}
                    component={_props.line1Component ?? _props.text?.line1Component ?? "div"}>
            {line1}
        </Typography>
    ) : undefined;

    const line2Code = (_props.line2Include ?? _props.text?.line2Include ?? true) ? (
        <Typography variant={_props.line2Variant ?? _props.text?.line2Variant}
                    component={_props.line2Component ?? _props.text?.line2Component ?? "div"}>
            {line2}
        </Typography>
    ) : undefined;

    const line3Code = (_props.line3Include ?? _props.text?.line3Include ?? true) ? (
        <Typography variant={_props.line3Variant ?? _props.text?.line3Variant}
                    component={_props.line3Component ?? _props.text?.line3Component ?? "div"}>
            {line3}
        </Typography>
    ) : undefined;

    const mergeClassAndProps = (props, className1, className2) => {
        let {className, ...rest} = props ?? {};
        return {
            className: clsx(className1, className2, className),
            ...rest
        };
    };


    return (
        <Flexbox container {...rest} {...mergeClassAndProps(_props.container, className, "Person-container")}>
            <Flexbox item className="Person-avatarWrapper">
                <Avatar {...mergeClassAndProps(_props.avatar, "Person-avatar")}
                        key={person.key ?? person.handle ?? person.name}
                        image={src} />
            </Flexbox>
            <Flexbox item {...mergeClassAndProps(_props.text, "Person-text")}>
                {line1Code}
                {line2Code}
                {line3Code}
            </Flexbox>
        </Flexbox>
    );
};


export default PersonFlexbox;
