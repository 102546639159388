
import { createSlice } from "@reduxjs/toolkit"
// import userobjectApi from "../services/userobject"

const initialState = {
};

const profileSlice = createSlice({
  name: "userobject",
  initialState,
  reducers: {
    userobjectList: (state, action) => {
      const { payload } = action
      return {
        ...state,
        ...payload.data
      }
    },
    deleteUserobject: (state, action) => {
      const { payload } = action
      let typeData = {}

      if (state[payload.type]) {
        typeData = state[payload.type].filter((a) => {
         return a.id !== payload.id
        })
      }
      return {
        ...state,
        [payload.type] : [...typeData]
      }
    }
  },
  extraReducers: (builder) => {
    // console.log("gggggggggggggg => ", userobjectApi)
    // builder.addMatcher(userobjectApi.endpoints.getPokemon.matchFulfilled, (state, action) => {
    //   console.log("gggggggggggggg => ", state, action)
    //   // pretend this field and this payload data exist for sake of example
    //   // state.lastPokemonReceived = action.payload.name;
    // })
  }

})

// Action creators are generated for each case reducer function
export const { userobjectList, deleteUserobject } = profileSlice.actions

export default profileSlice.reducer


