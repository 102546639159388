import React from 'react';
import Parser from 'html-react-parser';

/**
 * Implementation of Image component with background-image:
 */

export const ImageBGI = (props) => {
    return (<div>not implemented yet</div>);
};

export default ImageBGI;
