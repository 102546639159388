export const MY_PROPERTY = 'MY_PROPERTY';
export const PROFILE = 'PROFILE';
export const INBOX = 'INBOX';
export const CALENDAR = 'CALENDAR';

export const PROPERTY_SEARCH = 'PROPERTY_SEARCH';
export const NEWS = 'NEWS';

export const PROPERTY_DETAILS = 'PROPERTY_DETAILS';

export const featureRoutesMap = {
    [MY_PROPERTY]: /\/my-property/,
    [INBOX]: /\/inbox/,
    [CALENDAR]: /\/calendar/,
    [PROFILE]: /\/profile/,
    [PROPERTY_SEARCH]: /\/properties/,
    [NEWS]: /\/news/,
    [PROPERTY_DETAILS]: /\/property/,
};
