/* eslint-disable import/first */

import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useTheme } from '../../muiTheming';

export const FooterImprint = (props) => {
    let {
        theme,
        copyright,
        imprintVariant,
        ...rest
    } = props;

    theme = useTheme();

    // Define a lazy creator for interpolation context. Fun with functions!
    let ctx = () => (ctx = {...props, theme, year: (new Date()).getFullYear()});

    copyright = theme.interpolate(copyright ?? theme.getProp('copyright'), ctx);

    imprintVariant = imprintVariant ?? theme.getProp('imprintVariant') ?? 'body2';

    return (
        <Grid item component="div" {...rest}>
            <Typography variant={imprintVariant}>{
                copyright
            }</Typography>
            <a onClick={()=>this.scrollToTop()}>Back to the top</a>
        </Grid>
    );
};

export default FooterImprint;
