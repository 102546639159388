export const profileUpdate = (payload) => {
  return {
    type: 'PROFILE_UPDATE',
    payload
  }
}

export const profileLoading = (payload) => {
  return {
    type: 'PROFILE_LOADING',
    payload
  }
}

export const completeProfileLoading = (payload) => {
  return {
    type: 'COMPLETE_PROFILE_LOADING',
    payload,
  };
}

export const completeProfileSuccess = (payload) => {
  return {
    type: 'COMPLETE_PROFILE_SUCCESS',
    payload,
  };
};

export const preferenceUpdate = (payload) => {
  return {
    type: 'PREFERENCE_UPDATE',
    payload
  }
}

export const passwordUpdate = (payload) => {
  return {
    type: 'PASSWORD_UPDATE',
    payload
  }
}

export const getUserData = (payload) => {
  return {
    type: 'GET_USER_DATA',
    payload
  }
}

export const getIdentityDocuments = (payload) => {
  return {
    type: 'GET_IDENTITY_DOCUMENTS',
    payload
  }
}

export const resetMessage = (payload) => {
  return {
    type: 'RESET_MESSAGE',
    payload
  }
}

export const updateMessage = (payload) => {
  return {
    type: 'UPDATE_MESSAGE',
    payload
  }
}

export const updateState = (payload) => {
  return {
    type: 'UPDATE_STATE',
    payload
  }
}


export const tokenError = (payload) => {
  return {
    type: 'TOKEN_ERROR',
    payload
  }
}

export const serviceError = (payload) => {
  return {
    type: 'SERVICE_ERROR',
    payload
  }
}

export const authError = (payload) => {
  return {
    type: 'API_ERROR',
    payload
  }
}
