/* eslint-disable import/first */

/**
 * @copyright © 2020, Starberry Ltd
 */

import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '../../muiTheming';

const AddressFormat = ({format, property, ...props}) => {

    const theme = useTheme();

    const getOption = (k) =>
          props[k] ??
          theme.getProp('addressFormat.'+k) ??
          theme.getProp('address.'+k) ??
          theme.addressFormat?.[k] ??
          theme.address?.[k];

    if (!property) return null;

    // Initial separator.  This is the fallbck
    let separator = getOption('separator');

    // Hardcoded address
    if ( property.display_address )
        return property.display_address;

    // Get the address format, either directly from the props (format), or
    // from the theme/subtheme
    let addr = format ??
        theme.getProp('addressFormat') ??
        theme.getProp('address') ??
        {"keys": ['address1', 'street_name', 'address2', 'district', 'address3', 'area', 'address4', 'town', 'city']};

    // It can be a function, array, object, string, etc., and may go
    // through several stages.  This should resolve it.
    let c = 0;
    while ((++c<10) && ['function', 'array', 'object'].indexOf(typeof addr)>-1) {

        if ('function' === typeof addr) {
            addr = addr.call(property, property);
            continue;
        }

        if (addr instanceof Array) {
            addr = addr.filter(x=>x).join(separator);
            continue;
        }

        if ('object' === typeof addr) {
            separator = separator ?? addr.separator;
            if (addr.values) {
                if ('function' === typeof addr.values) {
                    addr = addr.values.call(property, property);
                    continue;
                }

                addr = addr.values;
                continue;
            }

            if (addr.keys) {
                if ('function' === typeof addr.keys) {
                    addr.keys = addr.keys.call(property, property);
                    continue;
                }

                addr = addr.keys.map(x=>property[x]);
                continue;
            }
        }
    }

    // Fill in any placeholders explicitly stated
    if ('string' === typeof addr)
        addr = addr.replace(/\${(.*?)}/g, (x, g)=> property[g]);

    // And if it didn't work, go for a worst-case
    if (!addr)
        addr = ['address1', 'street_name', 'address2', 'district', 'address3', 'area', 'address4', 'town', 'city'].map(x=>property[x]).filter(x=>x).join(separator ?? ', ');

    if (undefined === addr)
        return null;

    return addr;
};

AddressFormat.propTypes = {
    property: PropTypes.object,
    separator: PropTypes.string,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node])
};

AddressFormat.defaultProps = {
    // We don't include the main props here as we don't want defaults
    // overriding the theme values.
};


export default AddressFormat;
