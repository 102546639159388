/* eslint-disable import/first */

import React from 'react';

import { MuiThemeProvider, useTheme, withStyles } from '../../muiTheming';

import createStarberryTheme from '../../createStarberryTheme';

import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { SubthemeProvider } from '../Subtheme';
import AppFeaturesContextProvider from "../../AppFeaturesContextProvider"

const StarberryRootThemeContainer = ({children, ...props}) => {

    const theme = useTheme();

    // Add children executor wrapper
    let childrenFn = (children instanceof Function) ? children : ()=>children;

    const css = theme.getProp('sx') ?? {};
    if (! css) {
        return (<>{childrenFn(theme, undefined)}</>);
    }

    return React.createElement(withStyles(css)( props => (
        <div className={props.className}>{
            childrenFn(theme, undefined)
        }</div>
    )));
};

export const StarberryRootThemeProvider = ({theme, ...props}) => (
    <AppFeaturesContextProvider>
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createStarberryTheme(theme)}>
                <SubthemeProvider>
                    <CssBaseline />
                    <StarberryRootThemeContainer {...props} />
                </SubthemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    </AppFeaturesContextProvider>
);

export default StarberryRootThemeProvider;
