import React from "react";

import theme from "theme";

import Root from './core/components/Root';

import { AuthStoreProvider } from './core/services';

import { Provider } from 'react-redux';
import store from './core/redux/store';

export const wrapRootElement = ({element}) => (
    <Provider store={store}>
        <Root theme={theme}>
            <AuthStoreProvider>
                {element}
            </AuthStoreProvider>
            <div id="modal-portal"></div>
        </Root>
    </Provider>
);
