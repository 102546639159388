import React from 'react';
import Parser from 'html-react-parser';

/**
 * Implementation of Image component with gatsby-image
 */

export const ImageGatsby = (props) => {
    return (<div>not implemented yet</div>);
};

export default ImageGatsby;
