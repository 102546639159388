import {
    getConfigValues,
    getAvailabilitySlots,
    getAvailabilitySlotsByPostcode
} from "../api"
import { doLogout } from "../utils"

import { API_ERROR_MESSAGE } from "../constants";

const services = (dispatch, actions) => {

    const handleError = (error) => {
        if (error.response) {
          if (error.response?.data?.statusCode === 401) doLogout();
          dispatch(
            actions.formActions(
              'API_ERROR',
              {
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          dispatch(
            actions.formActions(
               'SERVICE_ERROR',
              {
              message: API_ERROR_MESSAGE,
              status: 500
            })
          );
        }
    }


    return({
        getConfigValues: payload => {
          dispatch(actions.formActions('DATA_LOADING'))
          getConfigValues(payload).then((res) => {
              dispatch(actions.formActions('GET_OPTIONS', res))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getAvailabilitySlots: payload => {
          dispatch(actions.formActions('DATA_LOADING'))
          getAvailabilitySlots(payload).then((res) => {
              dispatch(actions.formActions('GET_AVAILABILITY_SLOTS', res))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getAvailabilitySlotsByPostcode: payload => {
          dispatch(actions.formActions('DATA_LOADING'))
          getAvailabilitySlotsByPostcode(payload).then((res) => {
              dispatch(actions.formActions('GET_AVAILABILITY_SLOTS_BY_POSTCODE', res))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        updateState: payload => {
            dispatch(actions.formActions("UPDATE_STATE", payload))
        }
    })
}

export default services
