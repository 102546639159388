/* eslint-disable import/first */

import React from "react";

import { useTheme } from '../../muiTheming';

import withGriditemProps from '../../withGriditemProps';

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const cleanMenu = (entries) => {
    if ('string' === typeof entries)
        return entries.split(/\s*;\s*/).map(k => (<a href={k}>{k}</a>));

    if (entries instanceof Array)
        return entries;

    if (entries instanceof Object)
        return Object
        .entries(entries)
        .map((arr) => (<a href={arr[1]}>{arr[0]}</a>));

    return null;
};

export const FooterMenu = withGriditemProps(
    ({theme, title, entries, titleVariant, linkVariant, className, ...rest}) => {

        theme = useTheme();

        entries = cleanMenu(entries ?? {});

        titleVariant =
            titleVariant ??
            theme.getProp('titleVariant') ??
            'h1';

        linkVariant =
            linkVariant ??
            theme.getProp('linkVariant') ??
            'body1';

        // The bonus of using <nav> or <section> here rather than <div> is
        // that it isolates the <h1>
        return (
            <Grid item
                  component="nav"
                  className={[className,'FooterMenu'].filter(x=>x).join(' ')}
                  {...rest}
            >
                <Typography variant={titleVariant} component="h1">{
                    title
                }</Typography>
                <ul>{
                    ( entries.map ? (
                        entries.map((item, subindex) => (
                            <Typography key={`FooterMenu_link${subindex}`}
                                        variant={linkVariant}
                                        component="li"
                            >{
                                item
                            }</Typography>
                        ))) : null )
                }</ul>
            </Grid>
        );
    }
);

export default FooterMenu;
