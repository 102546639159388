/* eslint-disable import/first */

import React from 'react';


import { default as _Avatar } from "@mui/material/Avatar";

import { withSubtheme } from '../../withSubtheme';

export const Avatar = withSubtheme( ({theme, image, img, src, className, ...props}) => {

    const uri = image ?? src ?? img;

    const fixSize = (size) => (
        ('number' === typeof size && size >= 0 && size <= 20) ? theme.spacing(size)+"px" : size
    );

    let getOption = (k) =>
        props[k] ??
        theme.getProp(k) ??
        theme.getProp('avatar')?.[k];

    let avatarStyling = {};

    let variant = getOption('variant') ?? "square";

    let size = getOption('size') ?? 5;
    if (size)
        avatarStyling.width = avatarStyling.height = fixSize(size);

    if ('rounded' === variant) {
        avatarStyling.borderRadius = fixSize(getOption('rounding'));
    }

    return (<_Avatar {...props} src={uri} variant={variant} className={className} />);
}, 'avatar');

export default Avatar;
