import React from "react";
import PropTypes from 'prop-types';
import { apiUrl } from '../utils'
const baseURL = apiUrl()

const providersNames = [
  'facebook',
  'google'
];

export const AuthProvider = (props) => {
  if (!providersNames.includes(props.providerName))
    return(<p>Please use available provider only {JSON.stringify(providersNames)}</p>)
  return(
    <a href={`${baseURL}/connect/${props.providerName}`} className={`provider ${props.providerName}`}>
      {props.providerLabel ? props.providerLabel : props.providerName}
    </a>
  )
}

AuthProvider.propTypes = {
  providerName: PropTypes.string.isRequired,
  providerLabel: PropTypes.string
}
