import React, {createContext, useReducer} from "react";
import Reducer from './reducer'


const initialState = {};

const ActionStoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <ActionContext.Provider value={[state, dispatch]}>
            {children}
        </ActionContext.Provider>
    )
};

export const ActionContext = createContext(initialState);
export default ActionStoreProvider;
