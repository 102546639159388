/* eslint-disable import/first */

import React from "react";

import combineClasses from '../../combineClasses';
import { useTheme } from '../../muiTheming';

import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";

import ListItem from "@mui/material/ListItem";
import ListItemText from '@mui/material/ListItemText';

const ListItemLink = (props) => <ListItem button component="a" {...props} />;

import Flexbox from '../Flexbox';
import BurgerIcon from '../BurgerIcon';

const HeaderMenuBurgerTree = ({menu, path, level}) => (

    Object.entries(menu).map( ([k,v]) => (
        'string' === typeof v ? (
            <Flexbox item
                  className={`HeaderMenuItem HeaderMenuLink HeaderMenuLevel${level}`}
                  key={JSON.stringify([...path,k])}
            >
                <ListItemLink href={v}>
                    <ListItemText primary={k} />
                </ListItemLink>
            </Flexbox>
        ) : (
            <Flexbox item
                  className={`HeaderMenuItem HeaderMenuTree HeaderMenuLevel${level}`}
                  key={JSON.stringify([k,v])}
            >
                <HeaderMenuBurgerTree menu={v} path={[...path,k]} level={level+1} />
            </Flexbox>
        )
    ))
);


export const HeaderMenuBurger = ({theme, themeClasses, className, menus, ...rest}) => {

    theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const anchorOrigin = {
        vertical: (
            rest.anchorOriginVertical ??
                theme.getProp('anchorOrigin.vertical') ??
                theme.getProp('anchorOriginVertical') ??
                'bottom'
        ),
        horizontal: (
            rest.anchorOriginHorizontal ??
                theme.getProp('anchorOrigin.horizontal') ??
                theme.getProp('anchorOriginHorizontal') ??
                'center'
        )
    };

    const transformOrigin = {
        vertical: (
            rest.transformOriginVertical ??
                theme.getProp('transformOrigin.vertical') ??
                theme.getProp('transformOriginVertical') ??
                'top'
        ),
        horizontal: (
            rest.transformOriginHorizontal ??
                theme.getProp('transformOrigin.horizontal') ??
                theme.getProp('transformOriginHorizontal') ??
                'center'
        )
    };

    const popoverClasses = themeClasses.popover ?? '';

    return (
        <>
            <Flexbox item className={combineClasses(className, 'HeaderMenuRoot')}>
                <IconButton aria-label="Main menu"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleClick}
                >
                    <BurgerIcon />
                </IconButton>
            </Flexbox>
            <Menu
                PopoverClasses={{paper: popoverClasses}}
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Flexbox item
                         role="navigation"
                         className="HeaderMenuContainer"
                         {...rest}
                >
                    <HeaderMenuBurgerTree menu={menus} path={[]} level={0} />
                </Flexbox>
            </Menu>
        </>
    );
};

export default HeaderMenuBurger;
