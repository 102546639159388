import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"

const initialState = {}

const FormOptionStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)
    return (
        <FormOptionContext.Provider value={[state, dispatch]}>
            {children}
        </FormOptionContext.Provider>
    )
}

export const FormOptionContext = createContext(initialState)
export default FormOptionStoreProvider
