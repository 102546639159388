
/* eslint-disable import/first */

import React from "react";

import FooterMenu from '../FooterMenu';

import Grid from "@mui/material/Grid";

export const cleanMenus = (menus) => {
    if (menus instanceof Array)
        return menus;

    if (menus instanceof Object) {
        return Object
            .entries(menus)
            .map((arr) => ({title: arr[0], entries: arr[1]}));
    }

    return undefined;
};

export const FooterMenus = ({menus, theme, className, style, ...rest}) => {

    if (!menus) menus = {};
    menus = cleanMenus(menus);

    return (
        <Grid container item
              component="div"
              className={className}
              style={style}
              {...rest}
        >{
            menus.map((node, ix) => (
                <FooterMenu key={`FooterMenu_${ix}`}
                            theme={theme}
                            title={node.title}
                            entries={node.entries}
                />
            ))
        }</Grid>
    );
};

export default FooterMenus;
