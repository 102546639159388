import { useContext } from 'react'
import { MyaccountContext } from './store'
// Services

import Services from './services'
// Actions
import * as Actions from './actions'

const useMyaccountState = () => {
  const [state, dispatch] = useContext(MyaccountContext)
  const store = {
    state: { ...state },
    services:{ ...Services(dispatch, Actions) },
  }

  return store
}

export { useMyaccountState }
