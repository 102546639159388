/* eslint-disable import/first */

import React from 'react';

import {
    withTheme as _withTheme,
    makeStyles as _makeStyles,
    withStyles as _withStyles,
} from '@mui/styles';

import {
    ThemeProvider as _MuiThemeProvider,
} from '@mui/material';
import { /* createTheme, */ useTheme as _useTheme } from "@mui/styles"

// In case we end up wrapping our own useTheme...
export const useTheme = _useTheme;
export const withTheme = _withTheme;
export const makeStyles = _makeStyles;
export const withStyles = _withStyles;
export const MuiThemeProvider = _MuiThemeProvider;
export const ThemeProvider = _MuiThemeProvider;
// export const createMuiTheme = createTheme;
