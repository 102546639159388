/* eslint-disable import/first */

import React from 'react';
import clsx from 'clsx';
import { useTheme, makeStyles } from '../../muiTheming';

/**
 * Implementation of Image component with <img src…>, with optional srcset.
 */

// Make styles that just use the arguments to useStyles() as the root styles.
const useStyles = makeStyles((theme) => ({
    root: (css) => (css),
}));


export const ImageImg = (props) => {

    const theme = useTheme();

    // Extract top-level props
    let {wrap, group, className, style, ...iprops} = props;

    // Extract inner props
    let {src, srcset, sizes, children, ...rest} = iprops;

    // Whether to wrap the image, and if so, what element?
    wrap = wrap ?? theme.getProp(`images.${group}.wrap`) ?? false;

    // Try to figure out if there's nested image CSS without wrap.  This
    // shouldn't happen, tbh... it's mainly for Storybook.
    // If you're going to use wrap, set "& img"
    // for filters; if you're not going to use wrap, don't set it.
    //
    // Alternatively, it'd be nice if we could do "& img, &img" (for
    // example), or "& img, &:is(img)"
    let cssData = theme.getProp(`images.${group}.sx`);
    if (!wrap) {
        if (cssData && undefined !== cssData['& img']) {
            cssData = Object.assign({}, cssData, cssData['& img']);
            delete(cssData['& img']);
        }
    }

    // Build class names from makeStyles + className (from props)
    const classes = useStyles(cssData);

    className = clsx(
            classes.root,
            rest.classes,
            theme.getProp(`images.${group}.className`),
            className
        );

    // Copy for debugging / error messaging
    let _srcset = srcset;
    let _src = src;

    // Prepare optional wrapper component
    let wrapFn;
    if (wrap) {
        // Prepare a wrap HOC; if wrap is truthy, use <div>. Otherwise,
        // hope the developer has given us something useful.

        //eslint-disable-next-line eqeqeq
        const Wrap = true==wrap ? 'div' : wrap;

        wrapFn = (contents) => (<Wrap className={className} style={style}>{contents}</Wrap>);
    }
    else {
        // Identity function
        wrapFn = contents => contents;
        // Pass styling to image element, rather than to wrapper
        iprops = { className, style, ...iprops};
    }

    if ('string' === typeof src) {
        // Cool.

        // This is probably not the best test for an SVG
        if (src.startsWith('<?xml') || -1 !== src.indexOf('<svg')) {
            return wrapFn(<img {...iprops} />);
        }

        // Continue
    }
    else if (src instanceof Array && !srcset) {
        srcset = src;
        src = undefined;
    }
    else if (src instanceof Object && !srcset) {
        srcset = src;
        src = undefined;
    }

    if (srcset instanceof Array) {
        // Process ['http://foo/bar.jpg 480w', 'http://foo/bleh.jpg'] into
        // string
        srcset = srcset.map((v) => {
            if ('string' !== typeof v)
                return undefined;

            const m = v.match(/^\s*([^\s\,]+)\s*(\s+(\d+)(w|px))?\s*$/);

            // Ignore malformed strings, as this might not actually be
            if (!m)
                return undefined;

            if ('px' === m[4])
                throw new Error(`'px' used in Image srcset/src where 'w' should be: ${v}`);

            if (m[2])
                return `${m[1]}${m[2]}`;

            // Unmarked value is a candidate for src
            if (!src) src = m[1];

            return m[1];
        }).filter(v=>v).join(',');
    }

    else if ('object' === typeof srcset) {
        // It's an object, so let's just _try_ to enumerate it, and hope
        // for the best.
        // Process {'480w':'http://foo/bar.jpg', '':'http://foo/bleh.jpg'}
        // into string
        srcset = Object.entries(srcset).map(([k, v]) => {
            if ('string' !== typeof v)
                return undefined;

            if ('' === k || 0 === k) {
                // Unmarked value is a candidate for src
                if (!src) src = v;
                return v;
            }

            const m = k.match(/(\d+)(w|px)/);
            if (!m)
                return undefined;

            if ('px' === m[2])
                throw new Error(`'px' used in Image srcset/src where 'w' should be: ${v}`);

            return `${v} ${m[0]}`;
        }).filter(x=>x).join(' ');
    }

    // Check to see if anything was successful.

    // If the processed string contains something non-whitespace
    if (srcset?.match && srcset.match(/\S/)) {
        // Okay.
    }
    else {
        // Nothing processed.

        // So, if there was input, then we failed to process it.
        if (_srcset && _srcset.match && _srcset.match(/\S/)) {
            throw new Error(`srcset ${_srcset} not understood`);
        }
        else {
            // Make sure any srcset is clear.
            srcset = undefined;
        }
    }

    return wrapFn(<img className={className} src={src} srcSet={srcset} sizes={sizes} {...rest} />);
};

export default ImageImg;
