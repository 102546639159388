import { getToken } from '../services/store/utils'

const collectResponseMessage = (result, error, isError, successMessage) => {
  let message = '';
  let severity = 'success';
  
  if (isError) {
      severity = 'error';
      switch(error?.status) {
          case "FETCH_ERROR":
          case 500:
              message = "Something went wrong. Please try again."
              break;
          case 401:
              console.log("messageDialog => ", error?.data?.message)
              message = error?.data?.message;
              // doLogout()
              break;
          default:
              message = "" 
      }

      return {
        message: message,
        severity: severity
      }
  }

  if (result.status === 'fulfilled')
    message = successMessage;
  else {
    severity = 'error';
    switch(result.status) {
        case "FETCH_ERROR":
        case 500:
            message = "Something went wrong. Please try again."
            break;
        case "rejected":
            message = result.error?.data?.message
            break
        case 401:
            console.log("messageDialog => ", error?.data?.message)
            message = error?.data?.message;
            // doLogout()
            break;
        default:
            message = "" 
    }
  }

  const messageDialog = {
      message: message,
      severity: severity
  }  
  return messageDialog;
}

const getHeader = () => {
    return {
      Authorization: `Bearer ${getToken()}`,
    }
  }

export {
  collectResponseMessage,
  getHeader
}