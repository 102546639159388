/* eslint-disable import/first */

/**
 * @copyright © 2020, Starberry Ltd
 */

import React from 'react';
import PropTypes from 'prop-types';

//import NumberFormat from "react-number-format";
import numberParse from 'multi-number-parse';

import { useTheme } from '../../muiTheming';


const decimalSeparators = {};
const getDecimalSeparator = (locale) => {
    if (undefined !== decimalSeparators[locale])
        return decimalSeparators[locale];

    try {
        let oo = Intl.NumberFormat(locale).formatToParse(1.1).reduce(([a,x]) => 'fraction'===x.type ? x.value : a);
        return (decimalSeparators[locale] = (oo ? oo[1] : '.'));
    }
    catch (e) {
        return (decimalSeparators[locale] = '.');
    }
};

export const stripNumber = (val, locale) => {
    let sep = getDecimalSeparator(locale);

    // Remove everything but digits and decimal points, and convert
    // localised decimal points to '.'
    return parseFloat(
        val
            .split('')
            .reduce((a,x)=>(a+('0123456789.'[`0123456789${sep}`.indexOf(x)] ?? '')), '')
    );
};

// Convert a string to a number, assuming currency rules.
// @todo: take into account local formatting, eg. using Globalize.js
export const parseIntlNumber = (str, locale) => {
    return numberParse(str, getDecimalSeparator(locale));
}

/*
    if ('number' === typeof str) return str;
    if ('string' !== typeof str) str = ''+str;


@todo: This is difficult.  Basically need a reverse of Intl.NumberFormat
capable of currency, multipliers, grouping, etc.

    // Get the current locale's group and decimal separators
    const threethree = Intl.NumberFormat(locale,{notation:'compact'}).formatToParts(10000 / 3);
    const [groupSep, decimalSep] = [threethree[1], threethree[5]];

    const nf = Intl.NumberFormat(locale, {notation:'compact', roundingType: 'compactRounding', maximumSignificantDigits: 20, useGrouping: false});
    str = str.trim();

    let m = str.match(/([a-zA-Z]+)$/);
    if (m) {
        const val = stripNumber(str);
        const test = (val+m[1]).toUpperCase();
        if (isNaN(val)) return '';

        // Now figure out the multiplier
        for (let n=val; n < val*10000000000000; n *= 10) {
            let sample = nf.format(n).toUpperCase();
            console.log(sample, test);
            if (sample == test) {
                console.log(`match ret ${n}`);
                return n;
            }
        }
    }
    const ret = stripNumber(str);
    if (isNaN(ret))
        return '';
    else
        return ret;
};
*/


export const formatCurrency = ({value, theme, ...props}) => {

    const getOption = (k) =>
          props[`force_${k}`] ??
          props[k] ??
          theme.getProp(k) ??
          theme.getProp(`currency.${k}`) ??
          theme.currency?.[k];

    const defaultOptions = {
        // I'm not sure how best to deal with multiple currencies right now, to be honest.
        //        "currency_code": "GBP",
        //        "currency_symbol": "£",

        // "compact" for 1.2M or "standard" for $1,200,000
        "notation": "standard",//"compact",

        // true = show pennies even if $x.00
        "pennies": false,

        // Separate pricing into locale-specific groups.
        "grouping": true,

        // Use "$" rather than "US$" (for example)
        "fullsymbol": false,

        "formatter": (value, options) => {
            let params = {
                style: "currency",
                notation: options.notation ?? 'standard',
                currencyDisplay: options.fullsymbol ?? false,
                useGrouping: options.grouping ?? false,
                roundingType: options.pennies ?? false,
                currency: options.currency_code ?? "GBP"
            };

            if (true === params.notation)
                params.notation = "compact";
            else if (!params.notation)
                params.notation = "standard";

            if (true === params.currencyDisplay)
                params.currencyDisplay = "symbol";
            else if (!params.currencyDisplay)
                params.currencyDisplay = "narrowSymbol";

            params.useGrouping = !!params.useGrouping;

            // Include all known digits of price
            if (params.roundingType) {
                params.roundingType = "fractionDigits";
            }
            else {
                params.maximumSignificantDigits = 20;
                params.minimumFractionDigits = 0;
                params.roundingType = "compactRounding";
            }

            try {
                return Intl.NumberFormat(options.locale, params).format(value);
            }
            catch (e) {
                console.error(e);
                return value;
            }
        }
    };

    let options = {
        notation:      getOption('notation'),
        fullsymbol:    getOption('fullsymbol'),
        grouping:      getOption('grouping'),
        pennies:       getOption('pennies'),
        currency_code: getOption('currency_code'),
        locale:        getOption('locale') ?? 'en-GB',
        formatter:     getOption('formatter'),
        qualifier:     props.qualifier  ?? ''
    };

    options = Object.entries(options).filter(([k,v])=>(undefined !== v));
    options = Object.fromEntries(options);
    options = Object.assign(defaultOptions, options);

    try {
        return options.formatter?.apply(theme, [value, options]);
    }
    catch (e) {
        console.error(e);
        return value;
    }
};


export const CurrencyFormat = ({value, theme, ...props}) => {

    const _theme = useTheme();
    theme = theme ?? _theme;
    
    try {
        return (<span>{formatCurrency({value, theme, ...props})}</span>);
    }
    catch (e) {
        console.error(e);
        return (<span>{value}</span>);
    }
};

CurrencyFormat.propTypes = {
    value: PropTypes.number,
    currency_code: PropTypes.string,
    notation: PropTypes.string,
    fullsymbol: PropTypes.bool,
    grouping: PropTypes.bool,
    pennies: PropTypes.bool,
    locale: PropTypes.string
};

CurrencyFormat.defaultProps = {
    // We don't include the main props here as we don't want defaults
    // overriding the theme values.
};


export default CurrencyFormat;
