/* eslint-disable import/first */

import React from "react";

import { makeStyles } from '../../muiTheming';

import combineClasses from '../../combineClasses';

import Flexbox from '../Flexbox';
import Container from "@mui/material/Container";

export const PageBlock = (props) => {
    let {theme, component, className, jss, contained, children, ...rest} = props;

    // `component={…}` is problematic.
    // https://stackoverflow.com/questions/64651768/mixing-style-functions-with-material-ui-grid-and-compon

    const name = theme?.name ?? undefined;

    const classes = (undefined !== jss) ?
          makeStyles({root:jss}, {name})(theme)
          :
          {};

    className = combineClasses(classes, className);

    // Add children executor wrapper
    let childrenFn = (children instanceof Function) ?
        children :
        (theme, className, rest) => children;

    // Do we want a <Container> inside the <footer> or not, or even
    // outside?  And do we want it fixed or fluid with/without maxWidth?
    let wrapFn = x => x;

    // @todo Add in `component={Component}` when issues are solved.

    if ('fixed' === contained) {
        wrapFn = x => (
            <Container fixed>
                <Flexbox container {...rest} className={className}>
                    {x}
                </Flexbox>
            </Container>
        );
    }
    else if ('outer' === contained) {
        // The double-<Container> here is so we can have the root styling
        // the _outside_ of the container (eg. the background stretching
        // across the whole window) while constraining the middle column.
        //
        // It may be possible to simplify this and get the Flexbox inside
        // (used to contain child Flexboxes) to act as the inner
        // <Container>.
        wrapFn = x => (
            <Container className={className} maxWidth={false}>
                <Container>
                    <Flexbox container {...rest}>
                        {x}
                    </Flexbox>
                </Container>
            </Container>
        );
    }
    else if (false === contained || "false" === contained || "no" === contained) {
        wrapFn = x => (
            <Flexbox item container {...rest} className={className}>
                {x}
            </Flexbox>
        );
    }
    else if (true === contained || "true" === contained || "yes" === contained) {
        wrapFn = x => (
            <Container>
                <Flexbox container {...rest} className={className}>
                    {x}
                </Flexbox>
            </Container>
        );
    }
    else if ( ('string' === typeof contained && !isNaN(parseInt(contained))) || 'number' === typeof contained) {
        wrapFn = x => (
            <Container maxWidth={contained}>
                <Flexbox container {...rest} className={className}>
                    {x}
                </Flexbox>
            </Container>
        );
    }
    else {
        wrapFn = x => (
            <Flexbox container {...rest} className={className}>
                {x}
            </Flexbox>
        );
    }

    return wrapFn(
        childrenFn(theme, className, rest)
    );
};

export default PageBlock;
