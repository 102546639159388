import React from 'react';
import Parser from 'html-react-parser';

/**
 * Implementation of Image component with <picture><source>
 */

export const ImagePicture = (props) => {
    return (<div>not implemented yet</div>);
};

export default ImagePicture;
