/* eslint-disable import/first */

import React from "react";

import withSubtheme from '../../withSubtheme';
import { useTheme } from '../../muiTheming';

import HeaderMenuTopBarDropdowns from './HeaderMenuTopBarDropdowns';
import HeaderMenuBurger from './HeaderMenuBurger';

import './HeaderMenuReset.css';

export const HeaderMenu = withSubtheme( ({theme, menu, ...rest}) => {

    theme = useTheme();

    let menus = theme.getProp("menus");
    let menutype = theme.getProp("type");

    switch (menutype) {
    case 'burger':
        return <HeaderMenuBurger
                   {...rest}
                   theme={theme}
                   menus={menus}
               />;

    case 'topbardropdown':
        return <HeaderMenuTopBarDropdowns
                   {...rest}
                   theme={theme}
                   menus={menus}
               />;

    default:
        return <></>;
    }

}, 'menu');

export default HeaderMenu;


