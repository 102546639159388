/* eslint-disable import/first */

import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '../../muiTheming';
import Box from "@mui/material/Box"

// Semantically, there's no reason Breadcrumbs should be a list.  Often
// we've used <ul>, but even an <ol> would be more appropriate.  Still,
// both <ol> and <ul> add formatting that just needs to be neutralised.
// So instead, this uses "<nav>[<div><a><span>label</span></a></div>]+</nav>".
//
// If this doesn't work, don't just alter this component's implementation:
// either parameterise the elements, or create a different component.
//
// Also consider https://css-tricks.com/markup-for-breadcrumbs/

const Breadcrumbs = ({theme, separator, entries, ...rest}) => {

    const _theme = useTheme();
    theme = theme ?? _theme;

    if (undefined === separator || "" === separator)
        separator = theme.getProp('breadcrumbSeparator') ?? ' • ';

    if (undefined === entries)
        return <></>;

    let tuples = Object.entries(entries);
    let count = tuples.length;

    if (!count)
        return <></>;

    return (
        <Box
            component="nav"
            sx={() => ({
                "& > div:last-child::after": {
                    content: '""'
                },
                "& > div::after": {
                    content: `"${separator}"`
                },
                "& > div": {
                    display: "inline"
                }
            })}
             itemScope
             itemType="http://schema.org/BreadcrumbList"
             {...rest}
        >
          {
              tuples.map(([url,text],ix) => url ? (
                  <div key={url}
                       itemScope
                       itemType="http://schema.org/Breadcrumb"
                  >
                    <a href={url}
                       title={text}
                       itemProp="url"
                       rel={ (0===ix ? 'index' : '') + " up".repeat(count - ix - 1) }
                    >
                      <span itemProp="title">{text}</span>
                    </a>
                  </div>
              ) : (
                  <div key={url}
                       itemScope
                       itemType="http://schema.org/Breadcrumb"
                  >
                    <a title={text}
                       itemProp="url"
                    >
                      <span itemProp="title">{text}</span>
                    </a>
                  </div>
              ))
          }
        </Box>
    );
};

Breadcrumbs.propTypes = {
    entries: PropTypes.object.isRequired,
    separator: PropTypes.string
};

Breadcrumbs.defaultProps = {
};

export default Breadcrumbs;
