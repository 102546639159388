/* eslint-disable import/first */


// @TODO  This doesn't really work very well yet;  it was one of the
// first components implemented and hasn't been improved much since.
// It needs some love.


// https://github.com/tommymcglynn/mortgage-calculator-react
// https://github.com/tommymcglynn/mortgage-js


import React from 'react';
import PropTypes from 'prop-types';
import withSubtheme from '../../withSubtheme';

import Select from "@mui/material/Select";
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { parseIntlNumber, formatCurrency } from '../CurrencyFormat';


// Convert a string to a number, assuming normal number rules.
// @todo: take into account local formatting, eg. using Globalize.js
const filterNumber = parseIntlNumber;

const filterCurrency = filterNumber;

//const useStyles = makeStyles((theme) => ({
//    form:    (css => css.form ?? {}),
//    result:  (css => css.result ?? {}),
//    heading: (css => css.heading ?? {}),
//    field:   (css => css.field ?? {}),
//    error:   (css => css.error ?? {}),
//}));

export const MortgageCalculator = withSubtheme( (props) => {

    let {theme,
         className,
         themeClasses: classes,

         locale,
         currency,

         defaultPrice,
         defaultDuration,
         defaultRate,
         defaultDepositPercentage,
         durationChoices,

         pricePlaceholder,
         loanPlaceholder,
         ratePlaceholder,
         durationPlaceholder,
         depositPlaceholder,

         headingText,
         headingComponent,
         headingVariant,
         resultPrefixText,
         resultPrefixComponent,
         resultPrefixVariant,
         resultSuffixText,
         resultSuffixComponent,
         resultSuffixVariant,
         resultComponent,
         resultVariant,
         errorMessage,
         ...rest} = props;

    locale = locale ??
        theme.getProp('locale') ??
        'en-GB';

    currency = currency ??
        theme.getProp('currency') ??
        'GBP';

    const curFormat = (value) => {
        const str = formatCurrency({force_notation:'standard',
                                    force_maximumSignificantDigits:32,
                                    value,
                                    theme,
                                    locale,
                                    currency,
                                    ...rest});
        return str;
    };

    headingText = headingText ??
          theme.getProp('headingText') ??
          'Mortgage Calculator';

    headingComponent = headingComponent ??
          theme.getProp('headingComponent') ??
          'h3';

    headingVariant = headingVariant ??
          theme.getProp('headingVariant') ??
          headingComponent;

    resultPrefixText = resultPrefixText ??
          theme.getProp('resultPrefixText') ??
          'Monthly repayment: ';

    resultPrefixComponent = resultPrefixComponent ??
          theme.getProp('resultPrefixComponent') ??
          'span';

    resultPrefixVariant = resultPrefixVariant ??
          theme.getProp('resultPrefixVariant') ??
          'body1';

    resultSuffixText = resultSuffixText ??
          theme.getProp('resultSuffixText') ??
          undefined;

    resultSuffixComponent = resultSuffixComponent ??
          theme.getProp('resultSuffixComponent') ??
          'span';

    resultSuffixVariant = resultSuffixVariant ??
          theme.getProp('resultSuffixVariant') ??
          'body1';

    resultComponent = resultComponent ??
          theme.getProp('resultComponent') ??
          'span';

    resultVariant = resultVariant ??
          theme.getProp('resultVariant') ??
          'body1';

    errorMessage = errorMessage ??
          theme.getProp('errorMessage') ??
          'All fields are mandatory';

    const [showerror, /*setShowerror*/] = React.useState(false);

    const _price = curFormat(filterCurrency(defaultPrice, locale));

    const _deposit = curFormat(defaultDepositPercentage
                               ? defaultPrice * defaultDepositPercentage/100
                               : 0);

    const [state, setState] = React.useState({
        price: _price,
        loan: 0,
        rate: defaultRate,
        deposit: _deposit,
        duration: defaultDuration,
        currency: currency,
        result: 0,
    });

    // let timeout = undefined;

    const updateState = (ass) => {

        let _state = Object.assign({}, state);

        let _duration = ass.duration ?? _state.duration;
        let _rate     = ass.rate     ?? _state.rate;
        let _price    = ass.price    ?? filterCurrency(_state.price, locale);
        let _loan     = ass.loan     ?? filterCurrency(_state.loan, locale);
        let _deposit  = ass.deposit  ?? filterCurrency(_state.deposit, locale);

        if (undefined !== ass.loan) {
            _deposit = parseInt(_price) - parseInt(_loan);
            _state.deposit = isNaN(_deposit) ? '' :
                curFormat(_deposit);
        }
        else if (undefined !== ass.deposit) {
            _loan = parseInt(_price) - parseInt(_deposit);
            _state.loan = isNaN(_loan) ? '' :
                curFormat(_loan);
        }
        else if (undefined !== _loan && undefined !== _deposit) {
            _price = parseInt(_loan) + parseInt(_deposit);
            _state.price = isNaN(_price) ? '' :
                curFormat(_price);
        }

        _rate = filterNumber(_rate, locale) / 100 / 12;
        _duration = filterNumber(_duration, locale);
        _loan = filterCurrency(_loan, locale);

        if (isNaN(_rate) || !_duration || isNaN(_duration)) {
            _state.result = '';
        }
        else if (_rate === 0) {
            _state.result = curFormat(Math.ceil(_loan / _duration));
        }
        else {
            /*
             *                                (1+rate)^n
             * monthly = principal * rate * --------------
             *                              (1-(1+rate)^n)
             */
            const q = Math.pow(1 + _rate, _duration);
            const _result = - _loan * _rate * (q / (1-q));

            //Loan amount * ((1 + Interest rate per annum/100) ^ Term of loan) / Term of loan / 12

            _state.result = isNaN(_result) ? '' :
                curFormat(Math.ceil(_result));
        }

        // If state has changed...
        if (Object.entries(_state).reduce((a,[k,v])=>(a || state[k] !== v), false)) {
            setState(_state);
        }
    };

    const handlePrice = (event) => {
        const price = filterCurrency(event.target.value, locale);
        updateState({price});
    };

    const handleLoan = (event) => {
        const loan = filterCurrency(event.target.value, locale);
        updateState({loan});
    };

    const handleDeposit = (event) => {
        let deposit = filterCurrency(event.target.value, locale);

        if (isNaN(deposit) || deposit < 0)
            deposit = 0;
        else if (state.price > 0 && deposit > state.price)
            deposit = state.price * defaultDepositPercentage/100.0;

        updateState({deposit});
    };

    const handleDuration = (event) => {
        let duration = parseFloat(event.target.value);

        if (isNaN(duration) || duration < 0) duration = defaultDuration;

        updateState({duration});
    };

    const handleRate = (event) => {
        let rate = parseFloat(event.target.value);

        if (isNaN(rate)) rate = defaultRate;
        else if (rate < 0) rate = 0;
        else if (rate > 100) rate = 100;

        updateState({rate});
    };

//    const classes = useStyles(theme.getProp('sx') ?? {});
//    React.useEffect(() => updateState({}));

    const durationField = durationChoices ?
          (<Select name="duration"
                   value={state.duration}
                   onChange={handleDuration}
           >{
               Object.entries(durationChoices).map(([k,v]) => (
                   <MenuItem key={k} value={k}>{v}</MenuItem>
               ))
           }</Select>) :
          (<TextField name="duration"
                     value={state.duration}
                     label={durationPlaceholder}
                     onChange={handleDuration}
                     className={classes.field}
           />);


    return (
        <form className={classes.form} noValidate autoComplete="off">
          <Typography variant={headingVariant} component={headingComponent} className={classes.heading}>
            {headingText}
          </Typography>
          <p>(Yeah, this is really broken.)</p>
          {showerror && (
              <Typography variant="body1" className={classes.error}>
                {errorMessage}
              </Typography>
          )}
          <input type="hidden" name="bot-field" />
          <TextField name="purchase_price"
                     value={state.price}
                     label={pricePlaceholder}
                     onChange={handlePrice}
                     className={classes.field}
          />
          <TextField name="deposit_available"
                     value={state.deposit}
                     label={depositPlaceholder}
                     onChange={handleDeposit}
                     className={classes.field}
          />
          {durationField}
          <TextField name="interest_rate"
                     value={state.rate}
                     label="Interest Rate (%)"
                     onChange={handleRate}
                     className={classes.field}
          />
          <TextField name="loan_amount"
                     value={state.loan}
                     label="Loan Amount"
                     onChange={handleLoan}
                     className={classes.field}
          />
          {state.result && (
              <div className={classes.result}>
                <Typography variant={resultPrefixVariant} component={resultPrefixComponent}>
                  {resultPrefixText}
                  </Typography>
                <Typography variant={resultVariant} component={resultComponent}>
                  {state.result}
                </Typography>
                <Typography variant={resultSuffixVariant} component={resultSuffixComponent}>
                  {resultSuffixText}
                </Typography>
              </div>
          )}
        </form>
    );
}, 'calculator');


MortgageCalculator.propTypes = {
    locale: PropTypes.string,
    errorMessage: PropTypes.string,

    headingText: PropTypes.string,
    headingVariant: PropTypes.string,
    headingComponent: PropTypes.string,

    resultPrefix: PropTypes.string,
    resultPrefixVariant: PropTypes.string,
    resultPrefixComponent: PropTypes.string,

    resultSuffix: PropTypes.string,
    resultSuffixVariant: PropTypes.string,
    resultSuffixComponent: PropTypes.string,

    defaultRate: PropTypes.number,

    defaultPrice: PropTypes.number,

    defaultDepositPercentage: PropTypes.number,
    depositPlaceholder: PropTypes.string,

    defaultDuration: PropTypes.number,
    durationPlaceholder: PropTypes.string,
};

MortgageCalculator.defaultProps = {
    locale: "en-GB",

    pricePlaceholder: "Purchase Price",
    loanPlaceholder: "Loan amount",
    ratePlaceholder: "Interest Rate (% AER)",
    durationPlaceholder: "Duration (Months)",
    depositPlaceholder: "Deposit available",

    defaultPrice: 1000000,

    defaultDuration: 25,

    defaultRate: 3.5,

    defaultDepositPercentage: 25,
};


export default MortgageCalculator;
