import React, {createContext, useReducer} from "react";
import Reducer from './reducer'
import { getUser } from '../utils';

const initialState = {
  user: getUser(),
  loading: false,
  error: null,
  status: null
};

const ProfileStoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <ProfileContext.Provider value={[state, dispatch]}>
            {children}
        </ProfileContext.Provider>
    )
};

export const ProfileContext = createContext(initialState);
export default ProfileStoreProvider;
