import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  showModal: false
};

const propPreferenceSlice = createSlice({
  name: "propPreference",
  initialState,
  reducers: {
    updateReduxPropertyPreference: (state, action) => {
      // switch (action.type) {
      //     case ''
      // }
      return {
        ...state,
        showModal: action.payload
      }
    },
    resetReduxPropertyPreference: (state, action) => {
      return {}
    }
  }
})

// Action creators are generated for each case reducer function
export const { updateReduxPropertyPreference, resetReduxPropertyPreference } = propPreferenceSlice.actions

export default propPreferenceSlice.reducer


