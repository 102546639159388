/* eslint-disable import/first */

import React from "react";

import withSubtheme from '../../withSubtheme';
import propertyClean from '../../propertyClean';

import PropertyResultFlexbox from './PropertyResultFlexbox';

export const PropertyResult = withSubtheme( ({theme, type, property, clean, ...rest}) => {

    type = type ?? theme.getProp("type") ?? 'flexbox';

    // Refactor and clean property data?
    if (undefined === clean) clean = true;
    if (clean)
        property = propertyClean(property, theme);

    // Use the required property result implementation
    //
    // The idea here is to allow different implementations of the
    // component through the same interface.  However, it should not be
    // done unless ABSOLUTELY NECESSARY -- ie. the functionality you need
    // can't be added to the existing implementation.
    //
    // If there is more than one implementation, then it's almost
    // unavoidable that all but one of them will be neglected and end up
    // obsolete and/or incompatible.  That's what happened to
    // PropertyResultCard.

    switch (type) {
    case 'gridbox':
    case 'flexbox':
    default:     return (<PropertyResultFlexbox
                             {...rest}
                             theme={theme}
                             property={property}
                         />);
    }
}, 'propertyresult');

export default PropertyResult;


