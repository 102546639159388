const Reducer = (state, action) => {
    switch (action.type) {
        case 'SERVICE_ERROR':
          return {
            ...state,
            loading: false,
            loading_activities: false,
            error: true,
            message: action.payload.message,
            status: action.payload.status
          };

        case 'API_ERROR':
            return {
              ...state,
              loading: false,
              loading_activities: false,
              error: true,
              message: action.payload.message,
              status: action.payload.status
            };

        case "DATA_LOADING":
            let params = {
                loading: true,
                loading_activities: true,
                error: false,
                success: false,
                message: "",
            }
            if (action?.payload)
                params = { ...params, ...action.payload }
            return {
                ...state,
                ...params
            }
        case "GET_ACTIVITIES":
            return {
                ...state,
                loading: false,
                loading_activities: false,
                activitiesData: action.payload,
            }
        default:
            return state
    }
}

export default Reducer
