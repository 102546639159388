import React from 'react';

// The idea here is to add the styling capabilities of MUI's <Box> to
// MUI's <Grid>.
//
// Yes, <Box> can do <Grid> stuff using the classes, but it doesn't
// support <Grid>'s props.
//
// It might be an idea to reimplement this nicely, using the CSS Grid
// layout so incredibly flexible layouts can be done solely in CSS.

import Grid from '@mui/material/Grid';

import { withTheme } from '../../muiTheming';

// At the time of writing, `experimentalStyled` applies the `sx` prop.
// I don't know when it'll go away.
import { styled } from '@mui/styles';

import {
    compose,
    borders,
    display,
    flexbox,
    grid,
    margin,
    padding,
    palette,
    positions,
    shadows,
    sizing,
    spacing,
    typography,
} from '@mui/system';

const compo = compose(
    borders,
    display,
    flexbox,
    grid,
    margin,
    padding,
    palette,
    positions,
    shadows,
    sizing,
    spacing,
    typography
);

const _Flexbox = styled(Grid)(compo);


// Extract a set of props (most likely from the component's props or the
// theme) from a list of known Props
// (eg. https://material-ui.com/api/grid/ )
//
export const extractBoxProps = (extractOption) => {

    // Single props that may have an alias:  these can be aliased;
    // so eg., if background=… or bgcolor=… is set, it's
    // extracted as bgcolor=….  Aliases override the unaliased.
    const options = {
        'xs': 'xs',
        'sm': 'sm',
        'md': 'md',
        'lg': 'lg',
        'xl': 'xl',
        'bgcolor': 'background',
        'color': 'foreground',
        'borderColor': 'borderColor',
        'borderRadius': 'borderRadius',
        'spacing': 'spacing',
        'justifyContent': 'justify',
        'alignItems': 'alignItems'
    };

    // Compound properties, eg. `borderTop`, `marginRight`:
    const roots = [
        'elevation',
        'border',
        'margin',
        'padding',
    ];
    const variants = [
        '', 'Top', 'Bottom', 'Left', 'Right', 'X', 'Y'
    ];

    // Extract the (options) and (roots × variants) from the props/theme.
    let extras = Object.entries(options).reduce(

        // Get the plain props, looking for the aliases first
        (a, [k,k2]) => {
            // Check the alias in the props or the theme, then the unaliased.
            let v = extractOption(k2);
            if (undefined === v) {
                // We didn't get the alias, try the unaliased.
                a[k] = extractOption(k);
            }
            else {
                // Save the aliased value
                a[k] = v;

                // Remove the unaliased from props anyway, so we don't have
                // excess stuff in the remainder.
                extractOption(k);
            }
            return a;
        },
        roots.reduce((a,c) => (
            variants.reduce((a2, c2) => {
                let k = `${c}${c2}`;
                let v = extractOption(k);
                if (undefined !== v) {
                    // We found it
                    a2[k] = v;
                }
                return a2;
            }, a)
        ), {})
    );

    // Breakpoint shortcuts
    const span = extractOption('span');
    if (span) {
        const boxFormat = {
            "full": {xs:12},
            "half": {xs:12,sm:6},
            "third": {xs:12,sm:6,md:4},
            "quarter": {xs:12,sm:6,md:3}
        };

        if (undefined !== boxFormat[span]) {
            Object.entries(boxFormat[span]).forEach( ([k,v]) => {
                extras[k] = v;
            });
        }
    }

    return extras;
};


export const Flexbox = withTheme( ({theme, themeClasses, children, className, ...rest}) => {

    const extractOption = (k) => {
        if (undefined !== rest[k]) {
            const ret = rest[k];
            delete rest[k];
            return ret;
        }
        return theme.getProp(k);
    };

    const boxprops = extractBoxProps(extractOption);

    // Eradicate the spacing prop if it's there.  I think this should
    // allow 'm' and 'p', etc. as supported by the 'spacing' style
    // function, but not throw the theme.spacing function in.
    delete boxprops.spacing;
    delete rest.spacing;

    return (
        <_Flexbox {...boxprops} className={className} {...rest}>{

            // Handle ThemedFlexbox's ability to have a function as
            // children.  If a function is passed, then it'll be executed
            // at render with the parameters (theme,props) and an
            // undefined `this`.

            (children instanceof Function) ?
                children.call(undefined, theme, rest) :
                children
        }</_Flexbox>
    );
});


export default Flexbox;
