/* eslint-disable import/first */

import React from 'react';

import withSubtheme from '../../withSubtheme';

import PageBlock from '../PageBlock';

export const PageContent = withSubtheme( ({theme, contained, className, children, ...props}) => {

    contained = contained ?? theme.getProp('contained');

    // <Grid> + styling has trouble with component="…":
    // https://stackoverflow.com/questions/64651768/mixing-style-functions-with-material-ui-grid-and-component
    // so omit `component="article"`

    /*
                <Grid container
                      role="main"
                      direction="column"
                >
                </Grid>
    */

    return (
        <PageBlock role="main"
                   className={className}
                   contained={contained}
                   {...props}
        >
            {children}
        </PageBlock>
    );
}, 'content');

export default PageContent;
