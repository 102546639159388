import React, {createContext, useReducer} from "react";
import Reducer from './reducer'
import { getUser } from '../utils';

const initialState = {
  user: getUser(),
  loading: false,
  error: null,
  status: null,
  pageTitle: null,
};

const AuthStoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <AuthContext.Provider value={[state, dispatch]}>
            {children}
        </AuthContext.Provider>
    )
};

export const AuthContext = createContext(initialState);
export default AuthStoreProvider;
