const Reducer = (state, action) => {
    switch (action.type) {
        case 'SERVICE_ERROR':
          return {
            ...state,
            loading: false,
            error: true,
            message: action.payload.message,
            status: action.payload.status
          };

        case 'API_ERROR':
            return {
              ...state,
              loading: false,
              error: true,
              message: action.payload.message,
              status: action.payload.status
            };

        case "DATA_LOADING":
            let params = {
                loading: true,
                error: false,
                success: false,
                message: "",
            }
            if (action?.payload) params = { ...params, ...action.payload }
            return {
                ...state,
                ...params,
            }

        case "GET_OPTIONS":
            return {
                ...state,
                loading: false,
                error: false,
                preLoaded: true,
                options: action?.payload?.data
            }
        case "GET_AVAILABILITY_SLOTS":
            return {
                ...state,
                loading: false,
                error: false,
                preLoaded: true,
                slots: action?.payload?.data
            }
        case "GET_AVAILABILITY_SLOTS_BY_POSTCODE":
            return {
                ...state,
                loading: false,
                error: false,
                preLoaded: true,
                slots: action?.payload?.data
            }
        case "UPDATE_STATE":
            return {
                ...state,
                ...action.payload
            }

        default:
            return state
    }
}

export default Reducer
