import {
    getPropertyAppointments,
    getPropertyFeedbacks,
    getPropertyOffers,
    getPropertyTenancies,
    getPropertyTransactions,
    getPropertyTasks,
    getWorksOrders,
    getTenancyDocuments,
    getPropertyDocuments,
    getPropertyPendingActions,
    getPropertyRecentActivities,
    getPropertyStats,
    getHasAppointments,
    getCalendarList,
    hideProperty,
    unHideProperty,
    getArchivedProperties,
} from "../api"
import { doLogout } from "../utils"

import { API_ERROR_MESSAGE } from "../constants";

const services = (dispatch, actions) => {

    const handleError = (error) => {
        if (error.response) {
          if (error.response?.data?.statusCode === 401) doLogout();
          dispatch(
            actions.propertyActions(
              'API_ERROR',
              {
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          dispatch(
            actions.propertyActions(
               'SERVICE_ERROR',
              {
              message: API_ERROR_MESSAGE,
              status: 500
            })
          );
        }
    }


    return({
        getPropertyAppointments: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyAppointments(payload).then(res => {
                dispatch(actions.propertyActions("GET_APPOINTMENTS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getPropertyFeedbacks: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyFeedbacks(payload).then(res => {
                dispatch(actions.propertyActions("GET_FEEDBACKS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getPropertyOffers: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyOffers(payload).then(res => {
                dispatch(actions.propertyActions("GET_OFFERS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getPropertyTenancies: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyTenancies(payload).then(res => {
                dispatch(actions.propertyActions("GET_TENANCIES", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getPropertyTransactions: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyTransactions(payload).then(res => {
                dispatch(actions.propertyActions("GET_TRANSACTIONS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getPropertyTasks: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyTasks(payload).then(res => {
                dispatch(actions.propertyActions("GET_TASKS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getWorksOrders: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getWorksOrders(payload).then(res => {
                dispatch(actions.propertyActions("GET_WORKS_ORDERS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getTenancyDocuments: payload => {
            if (!payload.loading)
                dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getTenancyDocuments(payload).then(res => {
                dispatch(actions.propertyActions("GET_DOCUMENTS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getPropertyDocuments: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyDocuments(payload).then(res => {
                dispatch(actions.propertyActions("GET_DOCUMENTS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getPendingActions: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyPendingActions(payload).then(res => {
                dispatch(actions.propertyActions("GET_PROPERTY_PENDING_ACTIONS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getCalendarList: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getCalendarList(payload).then(res => {
                dispatch(actions.propertyActions("GET_CALENDAR_LIST", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getRecentActions: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyRecentActivities(payload).then(res => {
                dispatch(actions.propertyActions("GET_PROPERTY_RECENT_ACTIVITIES", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getPropertyStats: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getPropertyStats(payload).then(res => {
                dispatch(actions.propertyActions("GET_PROPERTY_STATS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        hasAppointments: payload => {
            dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
            getHasAppointments(payload).then(res => {
                dispatch(actions.propertyActions("GET_HAS_APPOINTMENTS", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        updatePropertyStatus: payload => {
            dispatch(actions.propertyActions("CHANGE_STATE", payload))
        },
        updateState: payload => {
            dispatch(actions.propertyActions("UPDATE_STATE", payload))
        },
        hideProperty: payload => {
          dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
          hideProperty(payload).then((res) => {
            dispatch(actions.propertyActions('HIDE_MY_PROPERTY', res.data));
          })
          .catch((error) => {
            handleError(error)
          })
        },
        unHideProperty: payload => {
          dispatch(actions.propertyActions("DATA_LOADING", { loading: true }))
          unHideProperty(payload).then((res) => {
            dispatch(actions.propertyActions('UN_HIDE_MY_PROPERTY', res.data));
          })
          .catch((error) => {
            handleError(error)
          })
        },
        getArchivedProperties: (payload) => {
          dispatch(actions.propertyActions('DATA_LOADING'));
          getArchivedProperties(payload).then((res) => {
            dispatch(actions.propertyActions('GET_ARCHIVED_PROPERTIES', res.data));
          })
          .catch((error) => {
            handleError(error)
          })
        },
    })
}

export default services
