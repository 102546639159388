/* eslint-disable import/first */
import React from 'react';

import combineClasses from './combineClasses';
import { makeStyles, useTheme } from './muiTheming';

/**
 * Get CSS from the current theme (possibly subthemed), compile it, and
 * render the Component including the resulting className.
 *
 * If WrapperComponent is set (string for an HTML element, or a React
 * component), then use it as a wrapper and apply the className to that
 * instead.
 */
export const withThemeStyling = (Component, WrapperComponent = undefined, className1=undefined) => ({className:className2, ...props}) => {

    const theme = useTheme();

    let className;
    let classes = [];
    let sx = theme.getProp('sx');

    // If the theme/subtheme contains 'sx', then if it includes "root" or
    // "@global", it's "pre-wrapped".  Otherwise, we want to wrap it with
    // "root" so makeStyles can deal with it.
    if (sx && !sx?.root && !sx?.['@global'])
        sx = { "root": sx };

    if (sx) {
        //
        // @todo I have a suspicion that keying styles might be causing
        // the loss-of-styling issues we've had.  Let's see...
        //
        //
        //     // The base of the styles generated for this subtheme.
        //     // As the full subtheme name can end up being quite long,
        //     // by default we'll hash the first components.
        //     let key;
        //
        //     // If longClassNames is set in the theme, don't hash the path --
        //     // expect long class names.
        //     if (theme.getProp('longClassNames')) {
        //         key = [theme.getProp('name'), Component.name].filter(x=>x).join('_');
        //     }
        //     else {
        //         // We'll cut the long theme names down to size with some hashing
        //         let parentname = theme._parent?.name;
        //
        //         // Trim the parent name from the start of the current name.
        //         let name = theme.getProp('name');
        //         if (parentname != name && name.startsWith(parentname+"_"))
        //             name = name.substring(parentname.length+1);
        //
        //         let hash = this._parent?.hash;
        //         if (!hash) {
        //             hash = parentname.split('').map(v=>v.charCodeAt(0)).reduce((a,v)=>a+((a<<7)+(a<<3))^v);
        //             this._parent.hash = hash;
        //         }
        //
        //         // Convert to string form without negatives (as the minus will
        //         // screw with CSS classname selection)
        //         hash = hash < 0 ? `t0${(-hash).toString(36)}` : `t${hash.toString(36)}`;
        //
        //         // and generate the resulting class, which should have a hash,
        //         // the current subtheme leaf, and the name of the component
        //         // being styled.
        //         key = [hash, name, Component.name].filter(x => x).join('-');
        //     }
        //
        //     // Create.  Should we memoize this?  Looks like it might just
        //     // cause trouble.  @todo determine if this will benefit
        //     // performance.
        //     classes = (makeStyles(sx, { name: key }))(theme);

        // Passing `{name: ...}` where the name changes with component
        // seems to make it lose styles.  So, we just do a straight
        // makeStyles() without naming.
        classes = (makeStyles(sx))(theme);

        className = combineClasses(className1, className2, classes.root ?? classes);
    }
    else {
        className = combineClasses(className1, className2);
    }

    switch (typeof WrapperComponent) {
        case 'string': case 'object': case 'function':
            return (
                <WrapperComponent className={className}>
                    <Component themeClasses={classes} {...props} />
                </WrapperComponent>
            );

        default:
            return (
                <Component themeClasses={classes} className={className} {...props} />
            );
    }
};

export default withThemeStyling;
