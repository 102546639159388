/* eslint-disable import/first */

import React from 'react';
import PropTypes from 'prop-types';

import Parser from 'html-react-parser';

// For maximum efficiency, just import and use <ImageImg> etc. directly,
// as you won't have to include all implementations and dependencies.
//
// However, for maximum flexibility (parameterisation in themes) import
// and use <Image> (ie. this component) and set method="..."
//
// Prefer maximum flexibility for generic work; switch to efficiency only
// if you really need to (eg. site speed sucks)

import ImageImg from './ImageImg';
import ImageBGI from './ImageBGI';
import ImagePicture from './ImagePicture';
import ImageGatsby from './ImageGatsby';

import { useTheme } from '../../muiTheming';

export const Image = (props) => {

    let {
        method, _method,
        group, _group,
        src, _src,
        srcset, _srcset,
        width, _width,
        height, _height,
        ...rest
    } = props;

    const theme = useTheme();

    // Define a lazy creator for interpolation context. Fun with functions!
    let ctx = () => (ctx = {...props, theme });

    // Interpolate dynamic props
    method      = _method ? theme.interpolate(method, ctx) : method;
    group       =  _group ? theme.interpolate(group, ctx)  : group;

    rest.src    =    _src ? theme.interpolate(src, ctx)    : src;
    rest.srcset = _srcset ? theme.interpolate(srcset, ctx) : srcset;
    rest.width  =  _width ? theme.interpolate(width, ctx)  : width;
    rest.height = _height ? theme.interpolate(height, ctx) : height;

    // The method of rendering: img, picture, bgi, etc.
    method = method ?? theme.getProp(`images.${group}.method`) ?? 'img';
    if (!method)
        throw new Error(`No Image method set for Starberry Components for group '${group}'`);

    switch (method) {
    case 'img':
        return (<ImageImg method={method} group={group} {...rest} />);

    case 'picture':
        return (<ImagePicture method={method} group={group} {...rest} />);

    case 'background':
    case 'bgi':
        return (<ImageBGI method={method} group={group} {...rest} />);

    case 'gatsby':
        return (<ImageGatsby method={method} group={group} {...rest} />);

    default:
        throw new Error(`Unknown Image method '${method}' for Starberry Components for group '${group}'`);
    }
};


Image.propTypes = {
    group: PropTypes.string,
    wrap: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ]),
    method: PropTypes.oneOf([
        'img', 'picture', 'background', 'bgi', 'gatsby'
    ]),
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ])
};

Image.defaultProps = {
};

export default Image;
