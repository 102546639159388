import React, {createContext, useReducer} from "react";
import Reducer from './reducer'


const initialState = {
    userObjects: {}
};

const UserObjectStoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <UserObjectContext.Provider value={[state, dispatch]}>
            {children}
        </UserObjectContext.Provider>
    )
};

export const UserObjectContext = createContext(initialState);
export default UserObjectStoreProvider;
