// Deepmerge, used to merge in default back
const isPlainObject = (x) => x && 'object' === typeof x && x.constructor === Object;

export const deepmerge_selective = (replaceElements, base, ...srcs) => {
    if (!isPlainObject(base))
        return base;

    var k, v;

    // Clone the initial object that we'll write into
    base = Object.assign({}, base);

    // Remove any props that are marked for replacement
    replaceElements.forEach(k => delete base[k]);

    for ( let src of srcs ) {
        if (isPlainObject(src)) {
            for ( [k,v] of Object.entries(src) ) {
                if ('__proto__' === k)
                    continue;

                if (-1 === replaceElements.indexOf(k) && isPlainObject(v) && base.hasOwnProperty(k)) {
                    base[k] = deepmerge_selective(replaceElements, base[k], v);
                }
                else if (isPlainObject(v)) {
                    // Clone object
                    base[k] = Object.assign({}, v);
                }
                else {
                    // Store data
                    base[k] = v;
                }
            }
        }
    }

    return base;
}

export const deepmerge = (base, ...srcs) => {
    if (!isPlainObject(base))
        return base;

    var k, v;

    // Clone the initial object that we'll write into
    base = Object.assign({}, base);

    for ( let src of srcs ) {
        if (isPlainObject(src)) {
            for ( [k,v] of Object.entries(src) ) {
                if ('__proto__' === k)
                    continue;

                if (isPlainObject(v) && base.hasOwnProperty(k)) {
                    base[k] = deepmerge(base[k], v);
                }
                else {
                    // Store data
                    base[k] = v;
                }
            }
        }
    }

    return base;
}

export default deepmerge;
