/* eslint-disable import/first */

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { makeStyles } from '../../muiTheming';

const useStyles = makeStyles((theme) => ({
    root: {
        "& *": { transition: "all 0.3s cubic-bezier(0.75, -0.1, 0.25, 1.0)" },
        "& .cc": { fill: "currentColor" },
        "&.active .BurgerFilling": { transform: "scaleX(0)" },
        "&.active .BurgerBottomBun": { transform: "rotate(-45deg)" },
        "&.active .BurgerTopBun": { transform: "rotate(45deg)" },
        "& .BurgerFilling": { transformOrigin: "12px 12px" },
        "& .BurgerBottomBun": { transformOrigin: "5.9642px 14.5px" },
        "& .BurgerTopBun": { transformOrigin: "5.9642px 9.5px" }
    }
}));

let BurgerIcon = ({active, className, ...props}) => {

    const classes = useStyles();

    className = [...new Set([className, classes.root, active?'active':undefined])].
        filter(x=>x).
        join(' ');

    return (
        <SvgIcon
            className={className}
            {...props}
        >
            <g>
                <rect    x="3" y="3" width="18" height="18" fill="rgba(1,1,1,0)" />
                <polygon className="cc BurgerFilling" points="3,13 21,13 21,11 3,11" />
                <rect    className="cc BurgerTopBun" x="3" y="6" width="18" height="2"  />
                <rect    className="cc BurgerBottomBun" x="3" y="16" width="18" height="2"  />
            </g>
        </SvgIcon>
    );
};


export default BurgerIcon;
